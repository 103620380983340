









// import { useEffect, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   useStripe,
//   useElements,
//   PaymentElement,
// } from "@stripe/react-stripe-js";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import axios from "axios";
// import { baseURL } from "../../../utils/constant";
// import { getCookie } from "../../../utils/auth";
// import { toast } from "react-toastify";

// const stripePromise = loadStripe("pk_test_51P9ZEtSHpdqeHSR3mbvWWdcVTHhtv3wT00Xr3b7KbkbDQ97wnutqAoZQcI6M6kH4xmzy0rgZs8vUKPRchLvp0kf000KkQTlKCb");
// const bearerToken = getCookie("bearerToken");
// const headers = {
//   Authorization: `Bearer ${bearerToken}`,
// };

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     const result = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: "http://localhost:3000/account/billing/invoices",
//       },
//     });

//     if (result.error) {
//       console.log(result.error.message);
//     } else {
//       // Payment success logic can go here if needed
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <div className="d-grid gap-2">
//         <button className="btn btn-orange mt-3" disabled={!stripe}>
//           Pay Now
//         </button>
//       </div>
//     </form>
//   );
// };

// export default function App() {
//   const [paymentCompleted, setPaymentCompleted] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { paymentIntentResponse, planName, amount, currency, paymentId } =
//     location.state;
//      console.log(paymentId)
//   const res = paymentIntentResponse;

//   const options = {
//     clientSecret: res.client_secret,
//   };

//   useEffect(() => {
//     if (paymentCompleted) {
//       axios
//         .post(
//           `${baseURL}payment/completePayment`,
//           {
//             paymentId: paymentId,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${getCookie("bearerToken")}`,
//             },
//           }
//         )
//         .then((response) => {
//           navigate("/account/billing");
//           toast(response.data.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           toast(error?.response?.data?.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         });
//     }
//   }, [paymentCompleted]);

//   const handlePaymentComplete = () => {
//     setPaymentCompleted(true);
//   };

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName}</h5>
//               <h5 className="mb-5">
//                 <p>
//                   Total : {amount} {currency.toUpperCase()}
//                 </p>
//               </h5>
//               <Col lg={12} className="p-3 border rounded-3">
//                 <Elements stripe={stripePromise} options={options}>
//                   <CheckoutForm />
//                 </Elements>
//               </Col>
//               <button
//                 className="mt-3 btn btn-orange"
//                 onClick={handlePaymentComplete}
//               >
//                 Complete payment
//               </button>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }



// import { useCallback, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Row, Col, Container } from "react-bootstrap";
// import { getCookie } from "../../../utils/auth";
// import useRazorpay from "react-razorpay";

// const bearerToken = getCookie("bearerToken");
// const headers = {
//   Authorization: `Bearer ${bearerToken}`,
// };

// export default function App() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const {
//     paymentIntentResponse,
//     planName,
//     amount,
//     currency,
//     orderId,
//     paymentId,
//   } = location.state || {};

//   const [Razorpay, isLoaded] = useRazorpay();

//   const handlePayment = useCallback(() => {
//     if (!isLoaded) {
//       console.log("Razorpay SDK is not loaded yet");
//       return;
//     }

//     const options = {
//       key: "rzp_test_qHsskkpVCBZZ09",
//       amount: amount * 100, // Amount should be in the smallest currency unit (e.g., cents)
//       currency: "USD", // Ensure the currency is lowercase
//       name: "SmartQC",
//       description: `Payment for ${planName} Plan`,
//       order_id: orderId, // Use the payment ID received from Billing component
//       handler: (response) => {
//         console.log("Payment successful", response);
//       },
//       prefill: {
//         // Optionally fill this with user info
//       },
//       notes: {
//         paymentId: paymentId,
//       },
//       theme: {
//         color: "#3399cc",
//       },
//     };

//     const rzpay = new Razorpay(options);
//     rzpay.on("payment.failed", function (response) {
//       console.error("Payment failed", response.error);
//       alert(response.error.description);
//     });

//     rzpay.open();
//   }, [Razorpay, isLoaded, orderId, planName, amount, currency, paymentId]);

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName} Plan</h5>
//               <h5 className="mb-5">
//                 <p>
//                   Total: {amount} {currency.toUpperCase()}
//                 </p>
//               </h5>
//               <button className="btn btn-orange px-4" onClick={handlePayment}>
//                 Pay Now
//               </button>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }
// Razorpay code .





// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import { useEffect, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   useStripe,
//   useElements,
//   PaymentElement,
// } from "@stripe/react-stripe-js";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import axios from "axios";
// import { baseURL } from "../../../utils/constant";
// import { getCookie } from "../../../utils/auth";
// import { toast } from "react-toastify";

// const stripePromise = loadStripe("pk_test_51P9ZEtSHpdqeHSR3mbvWWdcVTHhtv3wT00Xr3b7KbkbDQ97wnutqAoZQcI6M6kH4xmzy0rgZs8vUKPRchLvp0kf000KkQTlKCb");
// const bearerToken = getCookie("bearerToken");
// const headers = {
//   Authorization: `Bearer ${bearerToken}`,
// };

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     const result = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: "http://localhost:3000/account/billing/invoices",
//       },
//     });

//     if (result.error) {
//       console.log(result.error.message);
//     } else {
//       // Payment success logic can go here if needed
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <div className="d-grid gap-2">
//         <button className="btn btn-orange mt-3" disabled={!stripe}>
//           Pay Now
//         </button>
//       </div>
//     </form>
//   );
// };

// export default function App() {
//   const [paymentCompleted, setPaymentCompleted] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { paymentIntentResponse, planName, amount, currency, paymentId } =
//     location.state;
//      console.log(paymentId)
//   const res = paymentIntentResponse;

//   const options = {
//     clientSecret: res.client_secret,
//   };

//   useEffect(() => {
//     if (paymentCompleted) {
//       axios
//         .post(
//           `${baseURL}payment/completePayment`,
//           {
//             paymentId: paymentId,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${getCookie("bearerToken")}`,
//             },
//           }
//         )
//         .then((response) => {
//           navigate("/account/billing");
//           toast(response.data.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           toast(error?.response?.data?.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         });
//     }
//   }, [paymentCompleted]);

//   const handlePaymentComplete = () => {
//     setPaymentCompleted(true);
//   };

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName}</h5>
//               <h5 className="mb-5">
//                 <p>
//                   Total : {amount} {currency.toUpperCase()}
//                 </p>
//               </h5>
//               <Col lg={12} className="p-3 border rounded-3">
//                 <Elements stripe={stripePromise} options={options}>
//                   <CheckoutForm />
//                 </Elements>
//               </Col>
//               <button
//                 className="mt-3 btn btn-orange"
//                 onClick={handlePaymentComplete}
//               >
//                 Complete payment
//               </button>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// import { useCallback, useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import { getCookie } from "../../../utils/auth";
// import useRazorpay from "react-razorpay";

// const bearerToken = getCookie("bearerToken");

// export default function App() {
//   const location = useLocation();
//   const { planName, amount, currency, orderId, paymentId } = location.state || {};

//   const [Razorpay, isLoaded] = useRazorpay();
//   const [paymentOptions, setPaymentOptions] = useState(null);

//   useEffect(() => {
//     if (isLoaded && planName && amount && currency && orderId && paymentId) {
//       console.log("Updating payment options with: ", { planName, amount, currency, orderId, paymentId });
//       setPaymentOptions({
//         key: "rzp_test_qHsskkpVCBZZ09",
//         amount: amount * 100, // Amount should be in the smallest currency unit (e.g., cents)
//         currency: "USD", // Ensure the currency is lowercase
//         name: "SmartQC",
//         description: `Payment for ${planName} Plan`,
//         order_id: orderId, // Use the payment ID received from Billing component
//         handler: (response) => {
//           console.log("Payment Successful: ", response);
//           // Handle the successful payment response
//         },
//         prefill: {},
//         notes: {
//           paymentId: paymentId,
//         },
//         theme: {
//           color: "#3399cc",
//         },
//       });
//     } else {
//       console.log("Razorpay SDK not loaded or necessary data not available.");
//     }
//   }, [isLoaded, planName, amount, currency, orderId, paymentId]);

//   const handlePayment = useCallback(() => {
//     console.log("isLoaded: ", isLoaded);
//     console.log("paymentOptions: ", paymentOptions);
//     if (!isLoaded || !paymentOptions) {
//       console.error("Razorpay SDK not loaded or options not set yet.");
//       return;
//     }

//     const rzpay = new Razorpay(paymentOptions);
//     rzpay.on("payment.failed", function (response) {
//       console.error("Payment Failed: ", response.error.description);
//       alert(response.error.description);
//     });

//     rzpay.open();
//   }, [Razorpay, isLoaded, paymentOptions]);

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName} Plan</h5>
//               <h5 className="mb-5">
//                 <p>
//                   Total: {amount} {currency && currency.toUpperCase()}
//                 </p>
//               </h5>

//               <button className="btn btn-orange px-4" onClick={handlePayment}>
//                 Pay Now
//               </button>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// import { useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   useStripe,
//   useElements,
//   PaymentElement,
// } from "@stripe/react-stripe-js";
// import { Link, useLocation } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";

// const stripePromise = loadStripe("pk_test_51P9ZEtSHpdqeHSR3mbvWWdcVTHhtv3wT00Xr3b7KbkbDQ97wnutqAoZQcI6M6kH4xmzy0rgZs8vUKPRchLvp0kf000KkQTlKCb");

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     // We don't want to let default form submission happen here,
//     // which would refresh the page.
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js hasn't yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     const result = await stripe.confirmPayment({
//       //`Elements` instance that was used to create the Payment Element
//       elements,
//       confirmParams: {
//         return_url: "http://localhost:3000/account/billing/invoices",
//       },
//     });

//     if (result.error) {
//       // Show error to your customer (for example, payment details incomplete)
//       // console.log(result.error.message);
//     } else {
//       // Your customer will be redirected to your `return_url`. For some payment
//       // methods like iDEAL, your customer will be redirected to an intermediate
//       // site first to authorize the payment, then redirected to the `return_url`.
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <div className="d-grid gap-2">
//         <button className="btn btn-orange mt-3" disabled={!stripe}>
//           Pay Now
//         </button>
//       </div>
//     </form>
//   );
// };

// export default function App() {
//   const location = useLocation();
//   const { paymentIntentResponse, planName, amount, currency } = location.state;
//   const res = paymentIntentResponse;
//   // console.log(planName);
//   // console.log(amount);
//   // console.log(currency);
//   const options = {
//     // passing the client secret obtained from the server
//     clientSecret: res.client_secret,
//   };

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             {/* <Col lg={6} className="vh-100 d-flex align-items-center"> */}
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName}</h5>
//               {/* <p className="">{planDetails}</p> */}
//               <h5 className="mb-5">
//                 <p>
//                   Total : {amount} {currency.toUpperCase()}
//                 </p>
//               </h5>
//               <Col lg={12} className="p-3 border rounded-3">
//                 <Elements stripe={stripePromise} options={options}>
//                   <CheckoutForm />
//                 </Elements>
//               </Col>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../../utils/constant";
import { getCookie } from "../../../utils/auth";

const PayPalButton = ({
  planName,
  planDetails,
  amount,
  currency,
  orderId,
  paymentId,
  planId,
}) => {
  const navigate = useNavigate();
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": "AQ8IK1ICLMfcIQ4oK-MSPx0vhL9BL344pTY-LsQS18Fu5ExMetIPF7qU2brdBWR4NyD1rnpi8cLHanOR",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            console.log("Capture Details:", details); 

            const response = {
              // id: details.id,
              // status: details.status,
              // payment_source: details.payer,
              // purchase_units: details.purchase_units.map(unit => ({
              //   reference_id: unit.reference_id,
              //   shipping: unit.shipping,
              //   payments: {
              //     captures: unit.payments.captures.map(capture => ({
              //       id: capture.id,
              //       status: capture.status,
              //       amount: capture.amount,
              //       seller_protection: capture.seller_protection,
              //       final_capture: capture.final_capture,
              //       disbursement_mode: capture.disbursement_mode,
              //       seller_receivable_breakdown: capture.seller_receivable_breakdown,
              //       create_time: capture.create_time,
              //       update_time: capture.update_time,
              //       links: capture.links,
              //     })),
              //   },
              // })),
              // payer: {
              //   name: details.payer.name,
              //   email_address: details.payer.email_address,
              //   payer_id: details.payer.payer_id,
              // },
              // links: details.links,
              paymentId: orderId, // Adding orderId here
            };

            console.log("Formatted Response:", response); 

            fetch(`${baseURL}payment/completePayment`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                ...headers,
              },
              body: JSON.stringify(response),
            })
            .then(response => response.json())
            .then(data => {
              console.log('Server Response:', data);
              if (details.status === "COMPLETED") {
                navigate("/account/billing/showresponce/success");
              } else {
                navigate("/account/billing/showresponce/error");
              }
            })
            .catch(error => {
              console.error('Error:', error);
              navigate("/account/billing/showresponce/error");
            });
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

const PaymentCreate = () => {
  const location = useLocation();
  const { planName, planDetails, amount, currency, paymentId, orderId, _id } = location.state;

  return (
    <>
      <div className="mt-5 ms-5">
        <Link to="/account/billing/plans" className="text-decoration-none link-style-none text-black fs-6">
          <span className="text-muted fw-bold">Back to Plans</span>
        </Link>
      </div>
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col lg={12} className="d-flex justify-content-center align-items-center">
              <Col lg={6}>
                <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
                <h5>{planName}</h5>
                <h5 className="mb-5">
                  <p>
                    Total : {amount} {currency}
                  </p>
                </h5>
                <Col>
                  <PayPalButton
                    planName={planName}
                    planDetails={planDetails}
                    amount={amount}
                    currency={currency}
                    // paymentId={paymentId}
                    orderId={orderId} 
                    planId={_id}
                  />
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaymentCreate;



// import { useEffect, useCallback } from "react";
// import useRazorpay from "react-razorpay";
// import { useLocation } from "react-router-dom";

// const ShowResponse = () => {
//   const location = useLocation();
//   const { order_id, planName, amount, currency } = location.state;
//   const [Razorpay, isLoaded] = useRazorpay();

//   const handlePayment = useCallback(() => {
//     const options = {
//       key: "rzp_test_qHsskkpVCBZZ09",
//       amount: amount * 100, // Amount should be in the smallest currency unit (e.g., cents)
//       currency: "USD", // Ensure the currency is lowercase
//       name: "Your Company Name",
//       description: `Payment for ${planName}`,
//       order_id: order_id, // Use the payment ID received from Billing component
//       handler: (response) => {
//         console.log(response);
//       },
//       prefill: {
//         name: "Piyush Garg",
//         email: "youremail@example.com",
//         contact: "9999999999",
//       },
//       notes: {
//         address: "Razorpay Corporate Office",
//       },
//       theme: {
//         color: "#3399cc",
//       },
//     };

//     const rzpay = new Razorpay(options);
//     rzpay.open();
//   }, [Razorpay, order_id, planName, amount, currency]);

//   useEffect(() => {
//     if (isLoaded) {
//       handlePayment();
//     }
//   }, [isLoaded, handlePayment]);

//   return (
//     <div className="App">
//       <button onClick={handlePayment}>Click</button>
//     </div>
//   );
// };

// export default ShowResponse;

//////////////////////////////////////////////////////////////////////////////////////

// stripe code ........................

// import { useCallback, useEffect, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   useStripe,
//   useElements,
//   PaymentElement,
// } from "@stripe/react-stripe-js";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import axios from "axios";
// import { baseURL } from "../../../utils/constant";
// import { getCookie } from "../../../utils/auth";
// import { toast } from "react-toastify";
// import useRazorpay from "react-razorpay";

// const stripePromise = loadStripe("pk_test_51P9ZEtSHpdqeHSR3mbvWWdcVTHhtv3wT00Xr3b7KbkbDQ97wnutqAoZQcI6M6kH4xmzy0rgZs8vUKPRchLvp0kf000KkQTlKCb");
// const bearerToken = getCookie("bearerToken");
// const headers = {
//   Authorization: `Bearer ${bearerToken}`,
// };

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     const result = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: "http://localhost:3000/account/billing/invoices",
//       },
//     });

//     if (result.error) {
//       console.log(result.error.message);
//     } else {
//       // Payment success logic can go here if needed
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <div className="d-grid gap-2">
//         <button className="btn btn-orange mt-3" disabled={!stripe}>
//           Pay Now
//         </button>
//       </div>
//     </form>
//   );
// };

// export default function App() {
//   const [paymentCompleted, setPaymentCompleted] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { paymentIntentResponse, planName, amount, currency, orderId ,paymentId} =
//     location.state;
//      console.log(orderId)
//   const res = paymentIntentResponse;

//   const options = {
//     clientSecret: res.client_secret,
//   };

//   useEffect(() => {
//     if (paymentCompleted) {
//       axios
//         .post(
//           `${baseURL}payment/completePayment`,
//           {
//             paymentId: paymentId,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${getCookie("bearerToken")}`,
//             },
//           }
//         )
//         .then((response) => {
//           navigate("/account/billing");
//           toast(response.data.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           toast(error?.response?.data?.message, {
//             position: "bottom-left",
//             autoClose: 5000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//           });
//         });
//     }
//   }, [paymentCompleted]);

//   const handlePaymentComplete = () => {
//     setPaymentCompleted(true);
//   };

//   return (
//     <>
//       <div className="mt-5 ms-5">
//         <Link
//           to="/account/billing/plans"
//           className="text-decoration-none link-style-none text-black fs-6"
//         >
//           <span className="text-muted fw-bold">Back to Plans</span>
//         </Link>
//       </div>
//       <div className="vh-100 d-flex align-items-center">
//         <Container>
//           <Row>
//             <Col lg={6} className="mx-auto">
//               <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
//               <h5>{planName} Plan</h5>
//               <h5 className="mb-5">
//                 <p>
//                   Total : {amount} {currency.toUpperCase()}
//                 </p>
//               </h5>
//               { <Col lg={12} className="p-3 border rounded-3">
//                 <Elements stripe={stripePromise} options={options}>
//                   <CheckoutForm />
//                 </Elements>
//               </Col> }

//               { <button
//                 className="mt-3 btn btn-orange"
//                 onClick={handlePaymentComplete}
//               >
//                 Complete payment
//               </button> }
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }
