import React, { useState, useEffect } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function NewSidebar2() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      {/* <div className="py-3 px-4 bg-white vh-100"> */}
      <Col lg={2} className="py-4 px-4 bg-white vh-100 sticky-top">
        <div className="mb-4 text-center">
          <a
            className="text-decoration-none text-dark fw-lighter fs-2"
            href="#"
          >
            smart<span className="text-orange fw-bold">qc</span>
          </a>
        </div>
        <div className="d-grid gap-2 mb-4">
          <Button
            variant="btn btn-orange"
            className=""
            onClick={() => navigate("/createcampagin/create-type")}
          >
            New Campaign
            <span className="ms-1 fs-5"></span>
          </Button>
        </div>
        <Nav defaultActiveKey="/home" className="flex-column">
          <Link
            className="text-decoration-none fs-6 text-dark mb-3"
            to="/account/dashboard"
          >
            Dashboard
          </Link>
          <Link
            className="text-decoration-none fs-6 text-dark mb-3"
            to="/account/campaigns"
          >
            Campaigns 
          </Link>
          <Link
            className="text-decoration-none fs-6 text-dark mb-3"
            to="/account/billing"
          >
            Billing
          </Link>
          <Link
            className="text-decoration-none fs-6 text-dark mb-3"
            to="/account/profile"
          >
            Settings
          </Link>
        </Nav>
      </Col>
    </>
  );
}

export default NewSidebar2;
