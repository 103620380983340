import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { logOut } from "../utils/auth";
import cookie from "js-cookie";
import { getCookie } from "../utils/auth";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { baseURL } from "../utils/constant";
import Swal from "sweetalert2";
import { Button, Col } from "react-bootstrap";

const Dashboard = ({ toggleSidebar, sidebarOpen }) => {
  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({
    credits: 0,
    status: "",
    firstName: "",
    lastName: "",
    planExpiresOn: "",
    _id: "",
  });
  const [ShowAlert, setShowAlert] = useState(false);
  const [expireAlert, setExpireAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const handleLogOut = () => {
  //   const bearerToken = cookie.get("bearerToken");
  //   logOut(bearerToken);
  //   localStorage.clear();
  //   navigate("/login");
  // };

  // const handleLogOut = () => {
  //   const bearerToken = cookie.get("bearerToken");

  //   axios
  //     .post(`${baseURL}auth/logOut`,null,{
  //       headers: {
  //         Authorization: `Bearer ${bearerToken}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       toast(response.data.message || "Successfully Logout", {
  //         position: "bottom-left",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       logOut(bearerToken);
  //       localStorage.clear();
  //       navigate("/login");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast(err.response?.data?.message || "Failed to Logout", {
  //         position: "bottom-left",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure to sign out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const bearerToken = cookie.get("bearerToken");

        axios
          .post(`${baseURL}auth/logOut`, null, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            toast(response.data.message || "Successfully Logged Out", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            logOut(bearerToken);
            // logOut();
            localStorage.clear();
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
            toast(err.response?.data?.message || "Failed to Logout", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } else if (result.isDismissed) {
        navigate("/account/dashboard");
      }
    });
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          // console.log(response);
          const {
            credits,
            status,
            firstName,
            lastName,
            planExpiresOn,
            planName,
          } = response.data.data;
          setUser({
            credits,
            firstName,
            lastName,
            planExpiresOn,
            planName,
          });
          // console.log(planExpiresOn);
          setIsLoading(false);
          if (status !== "Active") {
            setShowAlert(true);
          }
          // console.log(new Date());
          const planExpiresDate = new Date(planExpiresOn);
          const currentDate = new Date();
          // Create new Date objects with only the year, month, and day components
          const planExpiresDateFormatted = new Date(
            planExpiresDate.getFullYear(),
            planExpiresDate.getMonth(),
            planExpiresDate.getDate()
          );
          const currentDateFormatted = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );

          if (planExpiresDateFormatted < currentDateFormatted) {
            setExpireAlert(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const getData = () => {
    axios
      .get(`${baseURL}auth/sendVerificationLink`, {
        headers: { ...headers },
      })
      .then((response) => {
        console.log(response);
        toast(response.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.log(err);
        toast(err.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      {/* {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : ( */}
      <Navbar expand="lg" className="bg-white">
        <Container fluid className="py-2">
          {/* <Navbar.Brand>
              <img src={Logo} width={150} alt="Logo" />
            </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto sign-out-text">
              {/* <Nav.Link href="#home"></Nav.Link>
                  <Nav.Link className="my-auto fs-6">
                    {user.credits} credits
                  </Nav.Link> */}

              {/* <Button
                    variant="btn btn-blue"
                    className="me-4"
                    onClick={() => navigate("/createcampagin/create-type")}
                  >
                    New Campaign +
                  </Button> */}
              <Nav.Link onClick={handleLogOut}>Sign out</Nav.Link>
              {/* <NavDropdown
                  title={`${user.firstName} ${user.lastName}`}
                  id="dropdown-menu-align-start"
                  className="my-auto fw-bold text-dark"
                >
                  <NavDropdown.Item
                    onClick={() => navigate("/account/profile")}
                  >
                    Account Settings
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    onClick={() => navigate("/account/billing")}
                  >
                    Billing
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogOut}>
                    Sign Out
                  </NavDropdown.Item>
                </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {ShowAlert && (
        <Container fluid className="mt-3">
          <Alert variant="warning">
            <Row>
              <p className="mb-0">
                Your email address is not verified. To verify your account,
                please find the link in your inbox or{" "}
                <a href="#" onClick={getData}>
                  click here
                </a>{" "}
                to resend verification link
              </p>
            </Row>
          </Alert>
        </Container>
      )}
      {expireAlert && (
        <Container fluid className="mt-3">
          <Alert variant="warning">
            <Row>
              <Col className="d-flex align-items-center">
                <div className="me-2">Your {user.planName} plan has expired.</div>
                <Button
                  className="ms-auto"
                  style={{ fontSize: "small" }}
                  variant="btn btn-orange"
                  onClick={() =>
                    navigate(`/account/billing/plans`)
                  }
                >
                  Upgrade Plan
                </Button>
              </Col>
            </Row>
          </Alert>
        </Container>
      )}

      {/* )} */}
    </>
  );
};

export default Dashboard;
