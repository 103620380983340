import React from "react";
// import success from "../../assets/img/dashbord/success.webp";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Complete from "../../../assets/img/home/icons8-success.gif"; 

function Success() {
  return (
    <>
      <Container className="vh-100 d-flex align-items-center">
        <Container>
          <Row>
            <Col lg={6} className="mx-auto text-center">
            <img src={Complete} alt="Success" className="mt-3" /> 
              <h1>Transaction Completed</h1>
              <div className="mt-5">
                <Link to="/account/billing" className="text-decoration-none text-black">
                  Back to Billing
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Success;
