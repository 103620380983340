import React from 'react'
import Navbar from "../../../components/Navbar.jsx";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "../../../components/Footer.jsx";

function GeneralInfo() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">General Information</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Can you provide more information about SmartQC?
                </Accordion.Header>
                <Accordion.Body>
                  SmartQC is the comprehensive data quality check tool you need to validate, monitor, and maintain the quality of your data.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )
}

export default GeneralInfo