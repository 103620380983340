import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Error() {
  return (
    <>
      <Container className="vh-100 d-flex align-items-center">
        <Container>
          <Row>
            <Col lg={6} className="mx-auto text-center">
            <h1 className='text-danger fw-bold'>X</h1>
            {/* <img src={Complete} alt="Success" className="mt-3" />  */}
              <h1>Transaction Failed</h1>
              <div className="mt-5">
                <Link to="/account/billing" className="text-decoration-none text-black">
                  Back to Billing
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Error