import axios from "axios";
import { baseURL } from "../../utils/constant";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setAuthentication } from "../../utils/auth";
import { toast } from "react-toastify";
import { IoReturnDownBack } from "react-icons/io5";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function Register({ setisAuthenticated }) {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setfirstName(value);
    } else if (name === "lastName") {
      setlastName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      firstName,
      lastName,
      email,
      password,
    };

    axios
      .post(`${baseURL}auth/register`, payload)
      .then((res) => {
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken);
        navigate("/account/dashboard");
        setisAuthenticated(true);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      <div className="d-flex align-items-center bg-login bg-white">
        <Container fluid>
          <Row>
            <Col
              lg={4}
              className=" bg-white vh-100 d-flex align-items-center mx-auto"
            >
              <Col lg={10} className="p-5 bg-white border rounded-3 mx-auto">
                <div className="py-3 ">
                  <span className="px-2">
                    <Link to="/" className="text-decoration-none text-black">
                      <IoReturnDownBack className="fs-3" />
                      Back To Home
                    </Link>
                  </span>
                </div>

                <h3 className="fw-bold display-6 mb-4">
                  Create your free account
                </h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="rounded-3 form-control-sm"
                      name="firstName"
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="border-1 border-bottom rounded-3 form-control-sm"
                      name="lastName"
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="rounded-3 form-control-sm"
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2">
                    <Form.Label>Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"} // Toggle input type
                        className="rounded-3 mb-3 form-control-sm"
                        name="password"
                        onChange={handleInputChange}
                      />
                      <span
                        className="position-absolute end-0 top-50 translate-middle-y me-2"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Icon changes */}
                      </span>
                    </div>
                  </Form.Group>

                  <div className="d-grid gap-2 mb-3">
                    <Button
                      variant="btn btn-orange"
                      type="submit"
                      className="rounded-3"
                    >
                      Sign up
                    </Button>
                  </div>
                </Form>

                <div className="text-center">
                  Have an account?{" "}
                  <Link to="/login" className="text-decoration-none">
                    Sign In
                  </Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
