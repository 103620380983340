import { Container, Row, Col } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import image1 from "../../assets/img/home/image-1.png";
import image2 from "../../assets/img/home/image-2.png";
import image3 from "../../assets/img/home/image-3.png";
import image4 from "../../assets/img/home/image-4.png";
import image5 from "../../assets/img/home/image-5.png";
import image6 from "../../assets/img/home/image-6.png";
import image8 from "../../assets/img/home/image-8.png";
import logo from "../../assets/img/dashbord/logo.png";

// import Default from '../../assets/img/home/default.png'
import * as Icon from "react-bootstrap-icons";
import Navbar from "../../components/Navbar.jsx";
import Footer from "../../components/Footer.jsx";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Navbar />
      <div className="d-flex align-items-center vh-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 px-lg-0 px-3 ms-auto my-auto">
              <h1 className="fw-bold display-5 mb-4">
                Enhance your leads with the future of data quality
              </h1>
              <h4 className="text-muted mb-4">
                SmartQC is your buddy for top-notch data quality!
              </h4>
              <Link
                className="btn btn-orange px-5 rounded-3"
                to="/register"
                role="button"
              >
                Get Started
              </Link>
            </div>
            <div className="col-lg-3 d-lg-block d-none me-auto">
              <img src={image1} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light-orange py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto py-5">
              <h2 className="fw-bold display-6 mb-4">
                Secured, trusted and reliable
              </h2>
              <h4 className="text-muted mb-5">
                Designed for both, collaboration and efficiency
              </h4>
              <div className="py-3 bg-white rounded-4">
                <img src={image6} className="img-fluid rounded-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="col-lg-10 mx-auto py-5">
            <h3 className="fw-bold display-6 mb-4">
              Almost everything you need
            </h3>
            <h4 className="text-muted">
              SmartQC comes with step-by-step quality process to help you engage
              your contacts
            </h4>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                <div className="col-lg-12 vh-40 p-5 bg-white rounded-3">
                  <h5 className="mb-4">Enrichment</h5>
                  <p>
                    Enrichment lets you look up person and company data based on
                    an email or domain.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="col-lg-12 vh-40 p-5 bg-white rounded-3">
                  <h5 className="mb-4">Discovery</h5>
                  <p>
                    Discovery lets you search for companies via specific
                    criteria.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="col-lg-12 vh-40 p-5 bg-white rounded-3">
                  <h5 className="mb-4">Email lookup</h5>
                  <p>Look up a person by their email address.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="col-lg-12 vh-40 p-5 bg-white rounded-3">
                  <h5 className="mb-4">Voice Authentication</h5>
                  <p>Authenticate identity with a phone call.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center vh-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 d-lg-block d-none ms-auto">
              <img src={image2} className="img-fluid" />
            </div>
            <div className="col-lg-7 px-lg-0 px-3 me-auto my-auto">
              <h3 className="fw-bold display-6 mb-4">AI-powered?</h3>
              <h4 className="text-muted">
                Unlike fully automated tools, SmartQC incorporates human
                validation to ensure the highest level of data accuracy
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light-green py-5">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-10 mx-auto pt-5 text-start">
              <h3 className="fw-bold display-6 mb-4">
                Integrate with your favourite apps
              </h3>
              <h4 className="text-muted">
                Built-in API integration that help your business grow faster
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row mb-5">
                <div className="col-lg-2 my-auto">
                  <img src={image3} className="img-fluid" />
                </div>
                <div className="col-lg-2 my-auto">
                  <img src={image4} className="img-fluid" />
                </div>
                <div className="col-lg-2 my-auto">
                  <img src={image5} className="img-fluid" />
                </div>
                <div className="col-lg-2 my-auto">
                  <img src={image8} className="img-fluid" />
                </div>
              </div>
              <a
                className="btn btn-orange px-5 rounded-3"
                href="#"
                role="button"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-10 py-5 mx-auto my-auto">
            <div className="col-lg-12 text-start p-5 rounded-3 border">
              <h2 className="fw-bold display-6 mb-4">Start for free</h2>
              <h4 className="text-muted mb-4">
                Get started with a new account in no time. No credit card
                required.
              </h4>
              <Link
                className="btn btn-orange px-5 rounded-3"
                to="/register"
                role="button"
              >
                Start for free
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      
    </>
  );
}
