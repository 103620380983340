import React from 'react'
import Betaimg from '../assets/img/beta-banner.png'

function Beta() {
  return (
    <>
    <img src={Betaimg} alt=""  className='beta-banner' />
    </>
  )
}

export default Beta