import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import Navbar from "../../../components/Navbar.jsx";
import Footer from "../../../components/Footer.jsx";

function SecurityAndPrivacy() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Security And Privacy</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                 How do I reset my password? 
                </Accordion.Header>
                <Accordion.Body>
                 To reset your password, please visit our website and click on the "Forgot Password" link on the login page. 
                 Follow the prompts to reset your password, and you'll receive an email with further instructions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                 How does SmartQC ensure data security? 
                </Accordion.Header>
                <Accordion.Body>
                 At SmartQC we take data security seriously. We use industry-leading encryption and security protocols to protect your data.
                  Additionally, we regularly undergo security audits and compliance checks to ensure the highest level of security for our users.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )

}

export default SecurityAndPrivacy