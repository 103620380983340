import React, { useState, useEffect } from "react";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";

// import Campaign from "./views/Campaign/Campaign.jsx";
// import CampaignType from "./views/CreateCampaign/CampaginTyle.jsx";
// import ImportLeads from "./views/Campaign/ImportLeads.jsx";
// import CreateCampaigns from "./views/CreateCampaign/createCampaign.jsx";


// account folder
import Dashboard from "./views/account/Dashboard/Dashboard.jsx";
import PastCampaign from "./views/account/PastCampaign/PastCampaign.jsx";
import Profile from "./views/account/Profile/Profile.jsx";
import Billing from "./views/account/Billing/Billing.jsx";
import DownloadPage from "./views/account/Billing/DownloadPage.jsx";
import OfferPlan from "./views/account/Billing/OfferPlan.jsx";
import Plans from "./views/account/Billing/Plans.jsx";
import Campaign from "./views/account/Campaign/Campaign.jsx";
import CampaginTyle from "./views/account/CreateCampaign/CampaginTyle.jsx";
import CreateCampaign from "./views/account/CreateCampaign/createCampaign.jsx";
import ShowResponce from "./views/account/Billing/ShowResponce.jsx";
import Success from "./views/account/Billing/Success.jsx";
import Error from "./views/account/Billing/Error.jsx";
import PaymentResponce from "./views/account/Billing/PaymentResponce.jsx";
import Allinvoice from "./views/account/Billing/Allinvoice.jsx";
import Razorpay from "./views/account/Billing/Razorpay.jsx";

//Pages Folder
import App from "./App";
// import Error from "./views/pages/Error.jsx";
// import Success from "./views/pages/Success.jsx";
import Login from "./views/pages/Login.jsx";
import ResetPassword from "./views/pages/ResetPassword.jsx";
import ForgotPassword from "./views/pages/forgotPassword.jsx";
import Register from "./views/pages/Register.jsx";
import Contact from "./views/pages/Contact.jsx";
import Cancellation from "./views/pages/Cancellation.jsx";
import Support from "./views/pages/Support.jsx";
import Termsofservice from "./views/pages/Termsofservice.jsx";
import Privacypolicy from "./views/pages/Privacypolicy.jsx";
import FaqPage from "./views/pages/FaqPage.jsx";


// FAQ Pages
import GeneralInfo from "./views/pages/faq/GeneralInfo.jsx";
import GettingStart from "./views/pages/faq/GettingStart.jsx";
import AppFeat from "./views/pages/faq/AppFeat.jsx";
import CampaignManagement from "./views/pages/faq/CampaignManagement.jsx";
import AccountManagement from "./views/pages/faq/AccountManagement.jsx";
import PlanAndBilling from "./views/pages/faq/PlanAndBilling.jsx";
import SecurityAndPrivacy from "./views/pages/faq/SecurityAndPrivacy.jsx";
import Troubleshooting from "./views/pages/faq/Troubleshooting.jsx";
import HelpAndSupport from "./views/pages/faq/HelpAndSupport.jsx";



import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Betaimg from './assets/img/beta-banner.png'
// import NewCampaignType from "./views/CreateCampaign/NewCampaignType.jsx";

import "./index.css";





// const stripePromise = loadStripe("pk_test_51P9ZEtSHpdqeHSR3mbvWWdcVTHhtv3wT00Xr3b7KbkbDQ97wnutqAoZQcI6M6kH4xmzy0rgZs8vUKPRchLvp0kf000KkQTlKCb");

const PrivateRoute = ({ isAuthenticated, ...props }) => {
  const token = localStorage.getItem("token");
  return isAuthenticated && token ? (
    <Outlet />
  ) : (
    <Navigate replace to="/pages/login" />
  );
};

const AppRoutes = () => {
  const [isAuthenticated, setisAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return !!token;
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setisAuthenticated(!!token);
  }, []);

  return (
    <>
    <BrowserRouter>
    <img src={Betaimg} alt=""  className='beta-banner' />
      <Routes>

        {/* Account Folder Pages */}

        <Route
          path="/account/dashboard"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/dashboard" element={<Dashboard />} />
        </Route>

        <Route
          path="/account/campaigns"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/campaigns" element={<PastCampaign />} />
        </Route>

        <Route
          path="/account/profile"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/profile" element={<Profile />} />
        </Route>

        <Route
          path="/account/billing/invoices/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/invoices/:_id" element={<Allinvoice />} />
        </Route>

        <Route
          path="/account/billing"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing" element={<Billing />} />
        </Route>

        <Route
          path="/account/billing/plans"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/plans" element={<Plans />} />
        </Route>
        <Route
          path="/account/billing/offerplan"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/offerplan" element={<OfferPlan />} />
        </Route>

        <Route
          path="/createcampaign"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="" element={<CreateCampaign />} />
        </Route>

        <Route
          path="/createcampagin/create-type"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route
            path="/createcampagin/create-type"
            element={<CampaginTyle />}
          />
        </Route>
        

        <Route
          path="/campaign/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/campaign/:_id" element={<Campaign />} />
        </Route>

        <Route
          path="/account/billing/showresponce"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/showresponce" element={<ShowResponce />} />
        </Route>

        <Route
          path="/account/billing/showresponce/success"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/showresponce/success" element={<Success />} />
        </Route>

        <Route
          path="/account/billing/showresponce/error"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
         <Route path="/account/billing/showresponce/error" element={<Error />} />
        </Route>

        

        <Route
         path="/downloadPage/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/downloadPage/:_id" element={<DownloadPage />} />
        </Route>
        
        {/* End of Account Folder Pages */}



        {/* Pages Folder */}
        <Route path="/" element={<App />} />
        
        <Route
          path="login"
          element={<Login setisAuthenticated={setisAuthenticated} />}
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/login/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/register"
          element={<Register setisAuthenticated={setisAuthenticated} />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/terms-of-service" element={<Termsofservice />} />
        <Route path="/support" element={<Support />} />
        <Route path="/faq" element={<FaqPage />} />
         {/* End of Pages Folder */}




        {/* FAQ Pages */}
        <Route path="/faq/general-information" element={<GeneralInfo />} />
        <Route path="/faq/getting-started" element={<GettingStart/>} />
        <Route path="/faq/app-features" element={<AppFeat/>} />
        <Route path="/faq/campaign-management" element={<CampaignManagement/>} />
        <Route path="/faq/account-management" element={<AccountManagement/>} />
        <Route path="/faq/plans-and-billing" element={<PlanAndBilling/>} />
        <Route path="/faq/security-and-privacy" element={<SecurityAndPrivacy/>} />
        <Route path="/faq/troubleshooting" element={<Troubleshooting/>} />
        <Route path="/faq/help-and-support" element={<HelpAndSupport/>} />
        



        {/* footer */}
        <Route path="/paymentResponce" element={<PaymentResponce />} />
        <Route path="/razorpay" element={<Razorpay />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
    </>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<AppRoutes />);
