import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import Navbar from "../../../components/Navbar.jsx";
import Footer from "../../../components/Footer.jsx";

function HelpAndSupport() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Help And Support</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                 What support options are available for SmartQC users?
                </Accordion.Header>
                <Accordion.Body>
                 SmartQC offers various support options including email support and a comprehensive knowledge base. 
                 Our dedicated support team is available to assist you with any questions or issues you may encounter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                 I'm experiencing an issue with your service; how can I get assistance? 
                </Accordion.Header>
                <Accordion.Body>
                 We apologize for any inconvenience you're experiencing. Please reach out to our customer support team either through our email, or form.
                 Our team will be happy to assist you in resolving the issue promptly.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                 Can you recommend a product/service that meets my specific needs? How can I contact your customer support team?
                </Accordion.Header>
                <Accordion.Body>
                   Absolutely! Please provide us with more information about your requirements, preferences, and our sales team will be happy to recommend the best service tailored to your needs. 
                   You can contact our customer support team via email at contact of smartqc, or through the live chat feature on our website.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                 Can you provide references or testimonials from current customers? 
                </Accordion.Header>
                <Accordion.Body>
                 Certainly! We have a list of satisfied customers who have provided testimonials about their experience with our product/service. 
                 We can share some references with you upon request.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                 What are the technical specifications of SmartQC? 
                </Accordion.Header>
                <Accordion.Body>
                 SmartQC is the comprehensive data quality check tool you need to validate, monitor, and maintain the quality of your data. 
                 For more detailed information, please visit on our website or contact our sales team for assistance.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    <Footer />
    </>
  )

}

export default HelpAndSupport