import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { Row, Col, Card, Button } from "react-bootstrap";
import SubNav from "../../../components/subNav";
import NewSidebar2 from "../../../components/NewSidebar2";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Dashboard = () => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [campaignData, setCampaignData] = useState({
    data: [],
    recordsTotal: 0,
  });
  const [isEmpty, setIsEmpty] = useState({
    isEmpty: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [dashboardData, setDashboardData] = useState({
    campaignCount: 0,
    leadCount: 0,
  });

  useEffect(() => {
    axios
      .get(`${baseURL}dashboard/getDashboardCount`, { headers })
      .then((response) => {
        const { campaignCount, leadCount } =
          response.data;
        setDashboardData({
          campaignCount,
          leadCount,
         
        });
        setIsLoading(false);
        // console.log(response);
      })
      .catch((err) => {
        // console.error(err?.response?.data?.message);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}campaign/getDataTableForCampaignByUserId`,
          {}, 
          { headers }
        );
        const responseData = response.data;
        setCampaignData(responseData);
        setFilteredData(responseData.data);
        setIsEmpty(responseData.isEmpty);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); 

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid className="">
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />

                <Col className="p-4">
                    <h4 className="fw-bold mb-4">Dashboard</h4>
                    <Row>
                      <Col lg={3}>
                        <Col lg={12} className="p-4 rounded-3 bg-white">
                          <h2 className="fw-bold">
                          {dashboardData.leadCount}
                          </h2>
                          <h5 className="fw-bold">
                           Leads
                          </h5>
                          
                        </Col>
                      </Col>
                      <Col lg={3}>
                        <Col lg={12} className="p-4 rounded-3 bg-white">
                          <h2 className="fw-bold">
                          {dashboardData.campaignCount}
                          </h2>
                          <h5 className="fw-bold">
                           Campaigns
                          </h5>
                          
                        </Col>
                      </Col>
                    </Row>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Dashboard;
