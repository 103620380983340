import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import { Row, Col, Button, ProgressBar, Badge } from "react-bootstrap";
import DashboardImage from "../../../assets/img/dashbord/dashboard.jpg";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import SubNav from "../../../components/subNav";
import { Link } from "react-router-dom";
import Sidebar2 from "../../../components/NewSidebar2";
import { HiMenu } from "react-icons/hi";
import NewSidebar2 from "../../../components/NewSidebar2";
import Loading from "../../../assets/img/dashbord/loading.gif";

// const formatDate = (dateString) => {
//   const currentDate = new Date();
//   const createdAtDate = new Date(dateString);
//   const timeDifference = currentDate.getTime() - createdAtDate.getTime();
//   const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

//   if (daysAgo === 0) {
//     return "Today";
//   } else if (daysAgo === 1) {
//     return "Yesterday";
//   } else {
//     return `${daysAgo} days ago`;
//   }
// };

const formatDate = (dateString) => {
  const currentDate = new Date();
  const createdAtDate = new Date(dateString);
  const currentDateWithoutTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const createdAtDateWithoutTime = new Date(
    createdAtDate.getFullYear(),
    createdAtDate.getMonth(),
    createdAtDate.getDate()
  );

  const timeDifference =
    createdAtDateWithoutTime.getTime() - currentDateWithoutTime.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "Tomorrow";
  } else if (daysDifference === -1) {
    return "Yesterday";
  } else if (daysDifference > 0) {
    return `${daysDifference} days from now`;
  } else {
    return `${-daysDifference} days ago`;
  }
};

const debounceTime = 300;

const PastCampaign = () => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [campaignData, setCampaignData] = useState({
    data: [],
    recordsTotal: 0,
  });
  const [isEmpty, setIsEmpty] = useState({
    isEmpty: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [debouncedSearchTimer, setDebouncedSearchTimer] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [newPayload, setNewPayload] = useState({
    page: "1",
    start: "0",
    length: `${campaignData.recordsTotal}`,
    columns: [
      { data: "campaignName", name: "", searchable: true, orderable: true },
      { data: "recordCount", name: "", searchable: true, orderable: true },
      { data: "userId", name: "", searchable: true, orderable: true },
    ],
    order: [{ column: 0, dir: "asc" }],
    search: { value: searchTerm, regex: false },
  });

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const handleSearch = async (e) => {
    try {
      const searchTermValue = e.target.value;
      setSearchTerm(searchTermValue);
      setCurrentPage(1);

      clearTimeout(debouncedSearchTimer);
      setDebouncedSearchTimer(
        setTimeout(async () => {
          try {
            const sortColumn = 0;
            const sortDirection = sortOrder === "asc" ? "asc" : "desc";

            const updatedPayload = {
              ...newPayload,
              search: {
                value: searchTermValue,
                regex: false,
              },
              order: [
                {
                  column: sortColumn,
                  dir: sortDirection,
                },
              ],
            };
            // console.log("Updated Payload:", updatedPayload);

            const response = await axios.post(
              `${baseURL}campaign/getDataTableForCampaignByUserId`,
              updatedPayload,
              { headers }
            );

            const responseData = response.data;
            // console.log("API Response Data:", responseData);
            setCampaignData(responseData);
            setFilteredData(responseData.data);
          } catch (err) {
            // console.error("API Request Error:", err);
          } finally {
            setIsLoading(false);
          }
        }, debounceTime)
      );
    } catch (error) {
      // console.error("Handle Search Error:", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}campaign/getDataTableForCampaignByUserId`,
        newPayload,
        { headers }
      );

      const responseData = response.data;
      // console.log(responseData);
      setCampaignData(responseData);
      setFilteredData(responseData.data);
      setIsEmpty(responseData.isEmpty);
      // console.log(setIsEmpty);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newPayload]);

  useEffect(() => {
    const groupedData = groupDataByDay(filteredData);
    const sortedGroups = groupedData.sort((groupA, groupB) => {
      const dateA = new Date(groupA[0].createdAt);
      const dateB = new Date(groupB[0].createdAt);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });
    const flattenedData = sortedGroups.flatMap((group) => group);
    setFilteredData(flattenedData);
  }, [activeTab, campaignData]);

  const handleSort = () => {
    const sortedData = filteredData.sort((campaignA, campaignB) => {
      const dateA = new Date(campaignA.createdAt);
      const dateB = new Date(campaignB.createdAt);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });

    setFilteredData([...sortedData]);
    setSortOrder((prevSortOrder) =>
      prevSortOrder === "desc" ? "asc" : "desc"
    );
  };

  const groupDataByDay = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.createdAt).toLocaleDateString("en-US");
      acc[date] = acc[date] || [];
      acc[date].push(item);
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  //calculate progressbar value
  const calculateProgress = (item) => {
    let progress = 0;
    if (item.isCSV) progress += 20;
    if (item.emailCheck) progress += 20;
    if (item.companyCheck) progress += 20;
    if (item.personCheck) progress += 20;
    if (item.voiceCallCheck) progress += 20;
    return progress;
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid className="">
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />
                {campaignData && isEmpty ? (
                  <Col className="p-4">
                    <Row>
                      <Col lg={6}>
                        <Col lg={12} className="p-4 rounded-3 bg-white">
                          <h5 className="fw-bold">
                            Your personalized dashboard awaits
                          </h5>
                          <p>Supercharge your data quality</p>
                          <Button
                            variant="btn btn-orange"
                            onClick={() =>
                              navigate("/createcampagin/create-type")
                            }
                          >
                            Get started
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col className="p-4">
                    <Col lg={6} className="my-auto">
                      <h4 className="fw-bold mb-4">Campaigns</h4>
                    </Col>
                    {/* <Col lg={6} className="text-end">
                      <Button
                        variant="btn btn-warning"
                        className="text-dark fw-bold btn-sm"
                        onClick={() => navigate("/createcampagin/create-type")}
                      >
                        New
                        <span className="ms-1 fs-5">
                          <IoAddCircle />
                        </span>
                      </Button>
                    </Col> */}
                    <div className="mb-4">
                      <input
                        className="form-control border-0 rounded-3"
                        id="myInput"
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </div>
                    {currentRows
                      .slice()
                      .sort((campaignA, campaignB) => {
                        const dateA = new Date(campaignA.createdAt);
                        const dateB = new Date(campaignB.createdAt);
                        return sortOrder === "desc"
                          ? dateB - dateA
                          : dateA - dateB;
                      })
                      .map((item, index) => (
                        <Col
                          lg={12}
                          className="p-3 mb-3 bg-white rounded-3"
                          key={index}
                        >
                          <Row>
                            <Col lg={5} className="my-auto">
                              <h5 className="fw-bold">
                                {item.campaignName}
                                {item.status === "Pending" && (
                                  <span className="badge text-bg-warning badge-sm ms-2 rounded-0">
                                    In progress
                                  </span>
                                )}
                                {item.status === "Complete" && (
                                  <span className="badge text-bg-success badge-sm ms-2 rounded-0">
                                    Complete
                                  </span>
                                )}
                                {item.status === "New" && (
                                  <span className="badge text-bg-primary badge-sm ms-2 rounded-0">
                                    New
                                  </span>
                                )}
                              </h5>
                              <p className="mb-0">
                                {formatDate(item.createdAt)}
                              </p>
                            </Col>
                            {/* 
                            <Col lg={5} className="my-auto">
                              {item.status === "Pending" && (
                                <ProgressBar
                                  animated={calculateProgress(item) < 100}
                                  now={calculateProgress(item)}
                                  variant="info"
                                  className="w-100"
                                  label={`${
                                    calculateProgress(item) === 100
                                      ? `${calculateProgress(item)}%`
                                      : `${calculateProgress(item)}%`
                                  }`}
                                />
                              )}
                              {item.status === "Complete" && (
                                <ProgressBar
                                  now={100}
                                  variant="success"
                                  className="w-100"
                                  label="Complete"
                                />
                              )}
                              {item.status === "New" && (
                                <ProgressBar
                                  now={100}
                                  variant="primary"
                                  className="w-100"
                                  label="New"
                                  // label={`${calculateProgress(item)}`}
                                />
                              )}
                            </Col> */}
                            <Col lg={2} className="my-auto text-end ms-auto">
                              <a
                                className="btn btn-sm btn-orange"
                                key={index}
                                onClick={() =>
                                  navigate(`/campaign/${item._id}`)
                                }
                              >
                                Details
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                  </Col>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default PastCampaign;
