import { Container, Row, Col } from "react-bootstrap";

import { useState } from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { TbChristmasTree, TbChristmasTreeOff } from "react-icons/tb";

const OfferPlan = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              {/* <NewSidebar2 /> */}
              <Col lg={12} className="px-0">
                {/* <SubNav/> */}
                <Col className="p-4">
                  <div className="pb-3">
                    <Link
                      to="/account/billing"
                      className="text-decoration-none text-black"
                    >
                      Back to Billing
                    </Link>
                  </div>
                  <h4 className="fw-bold pb-3">Coupens</h4>
                  <div className="d-flex align-items-center">
                    <Container fluid>
                      <Row className="mt-5">
                        <Col lg={3} className="my-auto ">
                          <div className="card border-0">
                            <div className="bg-success h-100 text-center p-5 rounded-4 ">
                            <TbChristmasTree className="text-white" size={50} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title fw-bold fs-2">
                                10 % Off
                              </h5>
                              <div>
                                <p>Starter</p>
                                <p className="text-success fw-bold fs-4">$ 999</p>
                              

                              </div>
                          
                              <div className="mt-3">
                                <p>Jun 01 - Jun 08</p>
                              </div>
                             <div className="text-end">
                             <a href="#" className="btn btn-primary">Get Offer</a>
                             </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3} className="my-auto ">
                          <div className="card border-0">
                            <div className="bg-info h-100 text-center p-5 rounded-4 ">
                            <TbChristmasTree className="text-dark" size={50} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title fw-bold fs-2">
                                15 % Off
                              </h5>
                              <div>
                                <p>Starter</p>
                                <p className="text-success fw-bold fs-4">$ 999</p>
                           

                              </div>
                          
                              <div className="mt-3">
                                <p>Jun 01 - Jun 08</p>
                              </div>
                             <div className="text-end">
                             <a href="#" className="btn btn-primary">Get Offer</a>
                             </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="my-auto ">
                          <div className="card border-0">
                            <div className="bg-danger h-100 text-center p-5 rounded-4 ">
                            <TbChristmasTree className="text-white" size={50} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title fw-bold fs-2">
                                20 % Off
                              </h5>
                              <div>
                                <p>Starter</p>
                                <p className="text-success fw-bold fs-4">$ 999</p>
                              

                              </div>
                          
                              <div className="mt-3">
                                <p>Jun 01 - Jun 08</p>
                              </div>
                             <div className="text-end">
                             <a href="#" className="btn btn-primary">Get Offer</a>
                             </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} className="my-auto ">
                          <div className="card border-0">
                            <div className="bg-warning h-100 text-center p-5 rounded-4 ">
                            <TbChristmasTree className="text-white" size={50} />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title fw-bold fs-2">
                                25 % Off
                              </h5>
                              <div>
                                <p>Starter</p>
                                <p className="text-success fw-bold fs-4">$ 999</p>
                            

                              </div>
                          
                              <div className="mt-3">
                                <p>Jun 01 - Jun 08</p>
                              </div>
                             <div className="text-end">
                             <a href="#" className="btn btn-primary">Get Offer</a>
                             </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
export default OfferPlan;
