import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/img/dashbord/logo.png";
import Navbar from "react-bootstrap/Navbar";
import { clearAuthentication, isLogin } from "../utils/auth";

function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSignInStatus = async () => {
      const signedInStatus = await isLogin();
      setIsSignedIn(signedInStatus);
    };

    const sessionTimeout = setTimeout(() => {
      console.log("Session timeout triggered in NavigationBar");
      clearAuthentication();
      setIsSignedIn(false);
      navigate("/");
    }, 20 * 60 * 1000); 

    checkSignInStatus(); 

    return () => clearTimeout(sessionTimeout);
  }, [navigate]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleSignInClick = () => {
    if (isSignedIn) {
      navigate("/account/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-light">
      <div className="container py-3 px-lg-0 px-3">
        <Navbar.Brand>
          <Link to="/">
            <img src={Logo} width={200} alt="Logo" />
          </Link>
        </Navbar.Brand>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item me-4">
              <Link className="nav-link" to="/contact">
                Contact Us
              </Link>
            </li>
            <li className="nav-item me-4">
              <Link
                className="nav-link"
                to={isSignedIn ? "/account/dashboard" : "/login"}
                onClick={handleSignInClick}
              >
                {isSignedIn ? "My Account" : "Sign in"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="btn btn-dark px-5 rounded-3"
                to="/register"
                role="button"
              >
                Start for free
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
