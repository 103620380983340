import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { baseURL } from "../utils/constant";

class UsageChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [], // Initialize with empty array
          title: {
            text: "Date", // Placeholder for x-axis data name
          },
        },
        yaxis: {
          title: {
            text: "Credits", // Placeholder for y-axis category name
          },
        },
      },
      series: [
        {
          name: "Usage Data",
          data: [], // Initialize with empty array
        },
      ],
    };
  }

  componentDidMount() {
    try {
      // Assuming you have a token stored in localStorage
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${baseURL}usage/getUserUsageByUserId`, { headers })
        .then((response) => {
          const { data } = response.data;

          // Extracting data from API response
          const graphData = data.graphData;
          const categories = graphData.map((item) => item.category);
          const usageData = graphData.map((item) => item.data);

          // Update state with API response data
          this.setState({
            options: {
              ...this.state.options,
              xaxis: {
                ...this.state.options.xaxis,
                categories: categories,
              },
            },
            series: [
              {
                name: "Usage Data",
                data: usageData,
              },
            ],
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height="300"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UsageChart;

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class UsageChart extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       options: {
//         chart: {
//           id: "basic-bar"
//         },
//         xaxis: {
//           categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
//         }
//       },
//       series: [
//         {
//           name: "series-1",
//           data: [30, 40, 45, 50, 49, 60, 70, 91]
//         }
//       ]
//     };
//   }

//   render() {
//     return (
//       <div className="app">
//         <div className="row">
//           <div className="mixed-chart">
//             <Chart
//               options={this.state.options}
//               series={this.state.series}
//               type="line"
//             //   width="500"
//               height="300"
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default UsageChart;
