import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "./Navbar.jsx";
import { Link } from "react-router-dom";

function Cancellation() {
  return (
    <>
      <div className="container border-top py-3">
        <div className="row">
          <div className="col-lg-10 px-lg-0 px-3 py-4 mx-auto my-auto text-end">
            <p>
              &copy; SmartQC |{" "}
              
              <Link to={"/terms-of-service"} className="text-decoration-none">
                Terms of Service
              </Link>{" "}
              |{" "}
              <Link to={"/support"} className="text-decoration-none">
                Support
              </Link>{" "}
              |{" "}
              <Link to={"/privacy-policy"} className="text-decoration-none">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to={"/cancellation"} className="text-decoration-none">
                Cancellation
              </Link>
              {" "}
              |{" "}
              <Link to={"/faq"} className="text-decoration-none">
                FAQ 
              </Link>
            </p>
            <p className="mb-0">
              Missing the cookie banner? Go ahead and browse, we don't track
              you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cancellation;
