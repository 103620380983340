import Container from "react-bootstrap/Container";
import { Row, Col, Button, Spinner, Pagination } from "react-bootstrap";
import SubNav from "../../../components/subNav";
import Badge from "react-bootstrap/Badge";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import NewSidebar2 from "../../../components/NewSidebar2";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { saveAs } from "file-saver";
import { FaList, FaTh } from "react-icons/fa";
import { FaCheck, FaExclamation } from "react-icons/fa6";
// import { CgProfile } from "react-icons/cg";
import { BsPersonCircle } from "react-icons/bs";
import Swal from "sweetalert2";

const IdCampain = () => {
  const { _id } = useParams();

  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
  });
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileInputDisabled, setFileInputDisabled] = useState(true);
  const [viewMode, setViewMode] = useState("grid");
  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseURL}campaign/getCampaignByCampaignId/${_id}`, {
        headers: headers,
      })
      .then((response) => {
        // console.log(response);
        const {
          campaignName,
          status,
          recordCount,
          csvData,
          qualifiedData,
          leadFields,
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
        } = response.data;

        setIdData({
          isCSV,
          emailCheck,
          personCheck,
          companyCheck,
          voiceCallCheck,
          campaignName,
          status,
          recordCount,
          leadFields,
        });
        setData(qualifiedData);
        setIsLoading(false);
        const totalItems = csvData.totalItems || 0;
        setTotalPages(Math.ceil(totalItems / entriesPerPage));
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          return navigate("/login");
        }
        if (err.response && err.response.status === 401) {
          return navigate("/");
        }
        setIsLoading(false);
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // Update filteredData to use searchQuery for filtering
  const filteredData = Data.filter((item) => {
    const fullName =
      `${item.first_name} ${item.last_name} ${item.email} `.toLowerCase();
    // const fullName =
    // `${item.first_name} ${item.last_name} ${item.email} ${item.phone_number}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  const handleEntriesPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    // console.log("Selected entries per page:", selectedValue);
    setSelectedEntriesPerPage(selectedValue);
    setCurrentPage(1);
  };
  // Calculate startIndex, endIndex, and currentData based on currentPage and entriesPerPage
  const startIndex = (currentPage - 1) * selectedEntriesPerPage;
  const endIndex = startIndex + selectedEntriesPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFileInputDisabled(false);
  }, [Data]);

  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setShowImportButton(false);
    setCrossIconVisible(true);
    showToast("File selected successfully");
  };

  const showToast = (message) => {
    toast(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleImportClick = async () => {
    fileInputRef.current.click();
  };
  // Rename the showToast function used for displaying toast notifications in the component
  const showToastComponent = (message) => {
    toast.info(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  // const handleSubmit = async () => {
  //   try {
  //     if (!selectedFile) {
  //       showToastComponent("Please select a file for import");
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("file", selectedFile);
  //     formData.append("campaignId", _id);

  //     axios
  //       .post(`${baseURL}campaign/importAnyFile`, formData, {
  //         headers: { ...headers },
  //       })
  //       .then((response) => {
  //         window.location.reload();
  //         toast(response.data.message, {
  //           position: "bottom-left",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });

  //         if (response.status === 200) {
  //           navigate(`/campaignid/${_id}`);
  //         } else {
  //           toast(response.data.message, {
  //             position: "bottom-left",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         toast(error?.response?.data?.message, {
  //           position: "bottom-left",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });

  //         if (error.response && error.response.status === 401) {
  //           navigate("/login");
  //         }
  //       });
  //   } catch (err) {
  //     console.error("Error:", err);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      if (!selectedFile) {
        showToastComponent("Please select a file for import");
        return;
      }

      Swal.fire({
        title:
          "<h6>Uploading this file will result in a deduction of your credits. Are you sure you want to proceed with the upload?</h6>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f76a28",
        cancelButtonColor: "#000",
        confirmButtonText: "Yes, upload it!",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("campaignId", _id);

          try {
            const response = await axios.post(
              `${baseURL}campaign/importAnyFile`,
              formData,
              {
                headers: { ...headers },
              }
            );

            window.location.reload();
            toast(response.data.message, {
              position: "bottom-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            if (response.status === 200) {
              navigate(`/campaignid/${_id}`);
            } else {
              toast(response.data.message, {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          } catch (error) {
            toast(error?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            if (error.response && error.response.status === 401) {
              navigate("/login");
            }
          }
        }
      });
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const DownloadFile = () => {
    axios
      .get(
        `${baseURL}campaign/downloadSampleImportFileByCampaignIdForClient/${_id}`,
        {
          headers: { ...headers },
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename=(.+)$/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : `sample_import_${_id}.csv`;

        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast(response.data.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        toast(err.response?.data?.message || "Failed to download file", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / selectedEntriesPerPage); // Use selectedEntriesPerPage instead of entriesPerPage
    const maxVisiblePages = 5;

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage and endPage if endPage is too close to totalPages
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {currentPage > 1 && (
          <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
            &laquo;
          </Pagination.Item>
        )}

        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
            className="bg-white"
          >
            {number}
          </Pagination.Item>
        ))}

        {currentPage < totalPages && (
          <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
            &raquo;
          </Pagination.Item>
        )}
      </Pagination>
    );
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}campaign/deleteCampaign/${id}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        // showToastComponent("Campaign deleted successfully");
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/account/campaigns");
      } else {
        // showToastComponent("Failed to delete campaign");
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      // showToastComponent("An error occurred while deleting campaign");
      toast.error(
        error.response?.data?.message || "Failed to delete campaign",
        {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // if (error.response && error.response.status === 401) {
      // }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "First Name",
      "Last Name",
      "Job Title",
      "Email",
      "Phone Number",
      "Company Name",
      "status",
    ];
    csvRows.push(headers.join(","));

    currentData.forEach((item) => {
      const row = [
        item.first_name,
        item.last_name,
        item.jobTitle,
        item.email,
        item.phone_number,
        item.company_name,
        item.status,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    saveAs(blob, "contacts.csv");
  };

  // const calculateProgress = (data) => {
  //   const steps = [
  //     { name: "Import CSV ", completed: data.isCSV },
  //     { name: "Email Lookup", completed: data.emailCheck },
  //     { name: "Prospect Verification", completed: data.personCheck },
  //     { name: "Company Verification", completed: data.companyCheck },
  //     {
  //       name: "Voice Authentication",
  //       completed: data.voiceCallCheck,
  //     },
  //   ];
  //   return steps;
  // };

  // const steps = calculateProgress(IdData);

  const calculateProgress = (data) => {
    // Step 1: Always the first step
    const initialStep = { name: "Import CSV", completed: data.isCSV };

    // Other steps
    const otherSteps = [
      { name: "Email Lookup", completed: data.emailCheck },
      { name: "Prospect Verification", completed: data.personCheck },
      { name: "Company Verification", completed: data.companyCheck },
      { name: "Voice Authentication", completed: data.voiceCallCheck },
    ];

    otherSteps.sort((a, b) => b.completed - a.completed);

    const allStepsCompleted = [initialStep, ...otherSteps].every(
      (step) => step.completed
    );

    const finalStep = {
      name: "Completed",
      completed: allStepsCompleted,
      finalStep: true,
    };

    return [
      initialStep,
      ...otherSteps,
      ...(allStepsCompleted ? [finalStep] : []),
    ];
  };

  const steps = calculateProgress(IdData);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />
                <Col className="p-4">
                  <Row>
                    <Col lg={12} className="mx-auto">
                      <div className="mb-3">
                        <Link
                          to="/account/campaigns"
                          className="text-decoration-none link-style-none text-black fs-6"
                        >
                          <span className="text-muted">Back to Campaigns</span>
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h5 className="fw-bold">{IdData.campaignName}</h5>

                        {IdData.status === "New" ? (
                          <Button
                            className="mb-4"
                            variant="btn btn-orange"
                            onClick={() => handleDelete(_id)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>

                      <Badge
                        className={
                          IdData.status === "Pending"
                            ? "bg-warning"
                            : IdData.status === "New"
                            ? "bg-primary"
                            : "bg-success"
                        }
                      >
                        {IdData.status === "Pending"
                          ? "In Progress"
                          : IdData.status}
                      </Badge>

                      <div className="d-flex justify-content-between py-1">
                        <div>
                          {IdData.leadFields.map((field) => (
                            <Badge
                              bg="success"
                              className="me-2 py-2"
                              key={field._id}
                            >
                              {field.label}
                            </Badge>
                          ))}
                        </div>

                        <span className="">{IdData.recordCount} Contacts</span>
                      </div>

                      <div className="d-flex justify-content-end">
                        {IdData.status === "Complete" ? (
                          <Button
                            className="mt-2"
                            style={{ fontSize: "small" }}
                            variant="btn btn-orange"
                            onClick={downloadCSV}
                          >
                            Export CSV
                          </Button>
                        ) : null}
                      </div>

                      <hr className="mb-4" />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="mx-auto">
                      {IdData.status === "New" ? (
                        <Col
                          lg={12}
                          className="mx-auto rounded-3 text-center text-black "
                        >
                          <Alert className="text-center" variant="warning">
                            <Icon.ExclamationTriangleFill className="text-warning me-2" />
                            Please take a look at the csv file format{" "}
                            <a
                              className="text-decoration-none cursor-pointer"
                              onClick={DownloadFile}
                            >
                              here
                            </a>{" "}
                            before importing the contacts.{" "}
                          </Alert>
                          {showImportButton && (
                            <>
                              <div className="text-center">
                                <div
                                  onDragOver={handleDragOver}
                                  onDrop={handleDrop}
                                  className="p-5 rounded-3 bg-white"
                                >
                                  <Button
                                    className="mb-4"
                                    variant="btn btn-orange"
                                    onClick={handleImportClick}
                                    disabled={fileInputDisabled}
                                  >
                                    Import csv document
                                  </Button>

                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                  />
                                  <p>or drop a file</p>
                                </div>
                              </div>
                            </>
                          )}

                          {showImportButton && (
                            <>
                              <a
                                to={`/campaign/${_id}`}
                                className="btn btn-orange mb-4"
                                onClick={handleImportClick}
                                disabled={fileInputDisabled}
                                style={{ display: "none" }}
                              >
                                Submit
                              </a>

                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </>
                          )}

                          {crossIconVisible && (
                            <>
                              <p
                                className="mb-4 text-success cursor-pointer"
                                onClick={() => {
                                  setShowImportButton(true);
                                  setCrossIconVisible(false);
                                  setSelectedFile(null);
                                }}
                              >
                                Selected File: {selectedFile.name} &#10006;
                              </p>
                              <span className="mb-4"></span>
                              <a
                                className="mb-4 btn btn-orange"
                                // variant="btn btn-blue"
                                onClick={handleSubmit}
                              >
                                Submit
                              </a>
                            </>
                          )}
                        </Col>
                      ) : IdData.status === "Pending" ? (
                        <Col lg={5} className="mx-auto text-center">
                          <p className="text-muted">
                            Sit back and relax. Your contacts are getting
                            verified.
                          </p>
                          <div className="row">
                            <section className="col-lg-12">
                              <h3 className="mb10">
                                {/* {IdData.campaignName} Campaign Details */}
                              </h3>
                              <div className="progress-div d-flex justify-content-center ">
                                <ul className="progress vertical py-5 vh-60">
                                  {steps.map((step, index) => (
                                    <li
                                      key={index}
                                      className={
                                        step.finalStep
                                          ? "inactive final-step"
                                          : step.completed
                                          ? "active"
                                          : "inactive"
                                      }
                                    >
                                      {step.finalStep ? (
                                        <FaExclamation
                                          style={{ color: "grey" }}
                                          className="crossmark"
                                        />
                                      ) : step.completed ? (
                                        <FaCheck
                                          style={{ color: "white" }}
                                          className="checkmark"
                                        />
                                      ) : (
                                        <FaExclamation
                                          style={{ color: "black" }}
                                          className="crossmark"
                                        />
                                      )}
                                      <span
                                        style={{
                                          fontWeight: step.finalStep
                                            ? "normal"
                                            : step.completed
                                            ? "bold"
                                            : "normal",
                                          color: step.finalStep
                                            ? "grey"
                                            : "black",
                                        }}
                                      >
                                        {step.name}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </section>
                          </div>
                        </Col>
                      ) : IdData.status === "Complete" ? (
                        <>
                          <Row className="me-auto">
                            {/* <Row className="d-flex justify-content-center"> */}
                            <Row className="m-0 p-0">
                              <Col lg={12}>
                                {show && (
                                  <Alert
                                    // className="text-center"
                                    variant="warning"
                                    onClose={() => setShow(false)}
                                    dismissible
                                  >
                                    <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                    <span className="me-2">
                                      Do you want to enrich the data?
                                    </span>
                                    <Button
                                      className="me-2"
                                      style={{ fontSize: "small" }}
                                      variant="btn btn-orange"
                                      onClick={downloadCSV}
                                    >
                                      Yes
                                    </Button>
                                    {/* <Button
                                          className=""
                                          style={{ fontSize: "small" }}
                                          variant="btn btn-orange"
                                          onClick={downloadCSV}
                                        >
                                          NO
                                        </Button> */}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                            <Col lg={3} className="my-auto">
                              <h5>Your contacts</h5>
                            </Col>
                            <Col lg={3} className="ms-auto">
                              <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                              />
                            </Col>
                            <Col lg={2}>
                              <div className="mb-3 ">
                                <Form.Select
                                  className="mx-auto w-75"
                                  value={selectedEntriesPerPage}
                                  onChange={handleEntriesPerPageChange}
                                >
                                  <option selected value={50}>
                                    50
                                  </option>
                                  <option value={100}>100</option>
                                  <option value={200}>200</option>
                                  <option value={500}>500</option>
                                </Form.Select>
                              </div>
                            </Col>
                            <Col lg={1} className="text-end">
                              <div className="d-flex py-2 text-end">
                                <div
                                  className={`icon ${
                                    viewMode === "list" ? "active" : ""
                                  }`}
                                  onClick={() => setViewMode("list")}
                                >
                                  <FaList
                                    size={20}
                                    color={
                                      viewMode === "list" ? "#f76a28" : "gray"
                                    }
                                  />
                                </div>
                                <div
                                  className={`icon ms-2 ${
                                    viewMode === "grid" ? "active" : ""
                                  }`}
                                  onClick={() => setViewMode("grid")}
                                >
                                  <FaTh
                                    size={20}
                                    color={
                                      viewMode === "grid" ? "#f76a28" : "gray"
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {viewMode === "list"
                              ? currentData.map((item, index) => (
                                  <Col
                                    lg={12}
                                    key={index}
                                    className="bg-white py-3 px-4 rounded mb-3"
                                  >
                                    <Row>
                                      <Col
                                        md={4}
                                        className="d-flex align-items-center"
                                      >
                                        <BsPersonCircle
                                          className="me-2"
                                          style={{
                                            fontSize: "25px",
                                            color: "#000",
                                          }}
                                        />
                                        <div>
                                          <p
                                            className="mb-0"
                                            style={{ fontSize: "18px" }}
                                          >
                                            {item.status === "Pending" ? (
                                              <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                            ) : item.status === "Verified" ? (
                                              <Icon.PatchCheckFill className="text-success me-2" />
                                            ) : item.status === "Failed" ? (
                                              <Icon.XCircleFill className="text-danger me-2" />
                                            ) : item.status === "" ? (
                                              <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                            ) : null}
                                            {item.first_name} {item.last_name}
                                          </p>
                                          <p
                                            className="mb-0 fw-bold"
                                            style={{ fontSize: "14px" }}
                                          >
                                            <Icon.BagFill /> {item.job_title}
                                          </p>
                                        </div>
                                      </Col>

                                      <Col md={4}>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Icon.EnvelopeFill /> {item.email}
                                        </p>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Icon.TelephoneFill />{" "}
                                          {item.phone_number}
                                        </p>
                                      </Col>
                                      <Col md={4}>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Icon.PostcardFill />{" "}
                                          {item.company_name}
                                        </p>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Icon.BuildingFill /> {item.industry}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                ))
                              : currentData.map((item, index) => (
                                  <Col lg={4} key={index}>
                                    <div className="bg-white py-3 px-4 rounded mb-3">
                                      <div className="text-end">
                                        {/* <Icon.PersonFill
                                          className="me-2 "
                                          style={{
                                            fontSize: "30px",
                                            color: "#f76a28",
                                          }}
                                        /> */}
                                      </div>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {item.status === "Pending" ? (
                                          <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                        ) : item.status === "Verified" ? (
                                          <Icon.PatchCheckFill className="text-success me-2" />
                                        ) : item.status === "Failed" ? (
                                          <Icon.XCircleFill className="text-danger me-2" />
                                        ) : item.status === "" ? (
                                          <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                        ) : null}
                                        {item.first_name} {item.last_name}
                                      </p>
                                      <p
                                        className="mb-0 fw-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.BagFill /> {item.job_title}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.EnvelopeFill /> {item.email}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.TelephoneFill />{" "}
                                        {item.phone_number}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.PostcardFill />{" "}
                                        {item.company_name}
                                      </p>
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Icon.BuildingFill /> {item.industry}
                                      </p>
                                    </div>
                                  </Col>
                                ))}
                          </Row>

                          <div className="float-end">{renderPagination()}</div>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default IdCampain;
