import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import Navbar from "../../../components/Navbar.jsx";
import Footer from "../../../components/Footer.jsx";

function Troubleshooting() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Troubleshooting</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                 I'm having trouble accessing my account, what should I do?
                </Accordion.Header>
                <Accordion.Body>
                 If you're having trouble accessing your account, please try resetting your password first. 
                 If the issue persists, please contact our customer support team for further assistance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                 Do you have any troubleshooting guides available? 
                </Accordion.Header>
                <Accordion.Body>
                 Yes, we provide troubleshooting guides for common issues with our products/services. 
                 You can find these guides on our website's support page or by contacting our customer support team for assistance.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )

}

export default Troubleshooting