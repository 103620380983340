import React from 'react'
import Navbar from "../../../components/Navbar.jsx";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "../../../components/Footer.jsx";

function PlanAndBilling() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Plan and Billing</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What plans does SmartQC offers?
                </Accordion.Header>
                <Accordion.Body>
                  SmartQC offers flexible plans to accomodate businesses of all sizes. Our plans are based on the features and usage levels you acquire, ensuring you get the best value for your investment.
                  For more details on plans, login to your account and navigate to Billing. Click on change plan to see plans we offer.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What payment methods do you accept?
                </Accordion.Header>
                <Accordion.Body>
                    We accept only credit/debit cards. During the checkout process, you'll have the option to enter card details and make payment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Can I cancel my subscription?
                </Accordion.Header>
                <Accordion.Body>
                    Yes, you can cancel your subscription at any time. To cancel your subscription, you must contact the support team by navigating to <Link to="/support" className='text-decoration-none' >Support</Link> page.
                </Accordion.Body>
              </Accordion.Item>
              
            </Accordion>
          </Col>
        </Row>
      </Container>
    <Footer />
    </>
  )
}

export default PlanAndBilling