import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../../utils/auth";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { Link, useNavigate , useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/img/dashbord/logo.png";
import Loading from "../../../assets/img/dashbord/loading.gif";

const DownloadPage = () => {
  const { _id } = useParams();
  const [paymentData, setPaymentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const componentRef = useRef();
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}payment/getPaymentByPaymentId/${_id}`,
        { headers }
      );
      const data = response.data.data;
      setPaymentData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${date.getDate()} ${month}, ${year}`;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchData();
  }, [_id, bearerToken]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={Loading} alt="" width={25} />
      </div>
    );
  }

  if (!paymentData) {
    return <div>No payment data found.</div>;
  }

  const { paymentId, updatedAt, firstName } = paymentData.payment;

  const convertDaysToMonths = (days) => {
    const months = Math.floor(days / 30); // Divide days by 30 to get months
    return months >= 1
      ? `${months} ${months > 1 ? "months" : "month"} validity`
      : `Contact Sales`;
  };

  return (
    <>
      <Col className="p-4">
      <a
          onClick={() => navigate(`/account/billing/invoices/${paymentData.payment._id}`)}
          className="mb-3 text-decoration-none text-dark"
        >
          Back to Invoice
        </a>
      </Col>
      {paymentData && (
        <div className="container mt-5 w-100 page-container" ref={componentRef}>
          <Row>
            <div className="card-header justify-content-center">
              <img src={Logo} width={200} alt="Logo" />
              <span className="float-end">
                <strong>Date:</strong>{" "}
                {getFormattedDate(paymentData.payment.updatedAt)}
              </span>
            </div>

            <Row className="mt-5 mb-4">
              <Col lg={6}>
                <h6 className="mb-3">
                  {paymentData.payment.paymentId}
                </h6>
              </Col>
            </Row>
            <hr />
            <Row className="pb-3">
              <Col lg={2} sm={5}>
                <div>
                  <span>
                    {" "}
                    {paymentData.user.firstName} {paymentData.user.lastName}
                  </span>
                </div>
                <div>
                  <span>{paymentData.user.email}</span>
                </div>
                {/* <div>
                  <span>
                    <strong>Mobile Number:</strong>
                  </span>{" "}
                </div> */}
              </Col>
            </Row>
            <hr />
            <Col lg={12} className="p-3 mb-3 rounded-3">
              <Row>
                <Col>
                  <h5>Term</h5>
                </Col>
                <Col>
                  <h5>Plan</h5>
                </Col>
                <Col>
                  <h5>Amount</h5>
                </Col>
              </Row>
            </Col>

            <Col lg={12} className="ms-2 rounded-3">
              <Row>
                <Col>
                  <h5>{convertDaysToMonths(paymentData.plan.planDuration)}</h5>
                </Col>
                <Col>
                  <h5>{paymentData.plan.planName} Plan</h5>
                </Col>
                <Col>
                  <h5>${paymentData.payment.amount}</h5>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col lg={12} className="mt-5 text-end">
                <Col>
                  <h5 className="fw-bold">
                    Subtotal:
                    <span className="ms-2">${paymentData.payment.amount}</span>
                  </h5>
                </Col>
                <Col>
                  <h5 className="fw-bold">
                    Total:
                    <span className="ms-2">${paymentData.payment.amount}</span>
                  </h5>
                </Col>
              </Col>
            </Row>
          </Row>
        </div>
      )}
      <div className="text-center">
        <button
          className="btn btn-orange mt-4"
          style={{ fontSize: "small" }}
          onClick={handlePrint}
        >
          Download / Print
        </button>
      </div>
    </>
  );
};

export default DownloadPage;
