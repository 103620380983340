import { Container, Row, Col, Spinner, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
// import SubNav from "../../../Components/subNav";
import { useNavigate, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NewSidebar2 from "../../../components/NewSidebar2";
import Table from "react-bootstrap/Table";
import { FaCheck } from "react-icons/fa6";
import Badge from "react-bootstrap/Badge";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bearerToken = getCookie("bearerToken");
  
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`${baseURL}plan/getPlanList`, {
          headers: { ...headers },
        })
        .then((response) => {
          const Response = response.data;
          // console.log(Response)
          const plansWithRegion = Response.data.map((plan, index) => {
            let region;
            switch (index) {
              case 0:
                region = "North America Only";
                break;
              case 1:
                region = "North America and EMEA";
                break;
              case 2:
                region = "All";
                break;
              case 3:
                region = "All";
                break;
              default:
                region = "Unknown";
            }
            return { ...plan, region: region, planId: index + 1 };
          });
          setPlans(plansWithRegion);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
        });
    };
    fetchData();
  }, []);

  const makePayment = async (_id, amount, planName, planDetails) => {
    const response = await fetch(`${baseURL}payment/createPaypalOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        planId: _id,
        amount,
        currency: "usd",
      }),
    });
    const responseData = await response.json();
    if (response.ok) {
      // Check if response status is OK
      // console.log(responseData.paymentIntentResponse); // Access paymentIntentResponse
      navigate("/account/billing/showresponce", {
        state: {
          // paymentIntentResponse: responseData.paymentIntentResponse,
          planName: planName,
          planDetails: planDetails,
          amount: amount,
          currency: "usd",
          orderId: responseData.orderId, // Include paymentId in state
        },
      });
    } else {
      // Handle error response
      console.error("Error:", responseData.error);
    }
  };



  const convertDaysToMonths = (days) => {
    const months = Math.floor(days / 30); // Divide days by 30 to get months
    return months >= 1
      ? `${months} ${months > 1 ? "months" : "month"} validity`
      : `Contact Sales`;
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              {/* <NewSidebar2 /> */}
              <Col lg={12} className="px-0">
                {/* <SubNav/> */}
                <Col className="p-4">
                  <div className="pb-3">
                    <Link
                      to="/account/billing"
                      className="text-decoration-none text-black"
                    >
                      Back to Billing
                    </Link>
                  </div>
                  <h4 className="fw-bold pb-3">Plans</h4>
                  <div className="d-flex align-items-center">
                    <Container fluid>
                      <Row>
                        {plans.map((plan) => (
                          <Col lg={3} key={plan._id} className="my-auto">
                            <Col
                              lg={12}
                              className={`border rounded-4  ${
                                plan.planName === "Advanced"
                                  ? "card-hover py-5 px-4"
                                  : "p-4 "
                              } `}
                            >
                              <h5>
                                {plan.planName}
                                <span>
                                  {plan.planName === "Advanced" ? (
                                    <Badge
                                      bg="light"
                                      text="dark"
                                      className="ms-1"
                                    >
                                      Recommended
                                    </Badge>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </h5>
                              <h3 className="fw-bold">
                                {plan.planPrice === 0
                                  ? "Contact Sales"
                                  : `$ ${plan.planPrice}`}
                              </h3>

                              <div>
                                {plan.planCredits === 0
                                  ? "Contact Sales"
                                  : plan.planCredits}{" "}
                                {plan.planCredits === 0 ? "" : "credits"}
                              </div>
                              <div>
                                {convertDaysToMonths(plan.planDuration)}
                              </div>
                              <div className="mb-3"> {plan.region}</div>

                              <div className="d-grid">
                              <Link
                                  
                                  className={`btn-sm btn btn-orange px-4 ${
                                    plan.planName === "Advanced"
                                      ? "button-hover"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    plan.planPrice === 0
                                      ? window.location.href = "/contact"
                                      : makePayment(
                                          plan._id,
                                          plan.planPrice,
                                          plan.planName,
                                          plan.planDetails
                                        )
                                  }
                                  type="button"
                                  disabled={plan.planPrice === 0}
                                >
                                  {plan.planPrice === 0
                                    ? "Contact Sales"
                                    : "Buy Now"}
                                </Link>

                              </div>

                              <div className="mt-2">
                                <div>
                                  <FaCheck /> Voice Validation
                                </div>

                                <div>
                                  <FaCheck /> LinkedIn Validation
                                </div>

                                <div>
                                  <FaCheck /> Email Validation
                                </div>

                                <div>
                                  <FaCheck /> Dupe Check
                                </div>

                                <div>
                                  <FaCheck /> Real Time Report Access
                                </div>

                                <div>
                                  <FaCheck /> QA Assurance
                                </div>
                              </div>
                            </Col>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
export default Billing;



































// import { Container, Row, Col, Spinner, Card } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import { getCookie } from "../../../utils/auth";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { baseURL } from "../../../utils/constant";
// // import SubNav from "../../../Components/subNav";
// import { useNavigate, Link } from "react-router-dom";
// import Nav from "react-bootstrap/Nav";
// import NewSidebar2 from "../../../components/NewSidebar2";
// import Table from "react-bootstrap/Table";
// import { FaCheck } from "react-icons/fa6";
// import Badge from "react-bootstrap/Badge";
// import Loading from "../../../assets/img/dashbord/loading.gif";

// const Billing = () => {
//   const navigate = useNavigate();
//   const [plans, setPlans] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const bearerToken = getCookie("bearerToken");
//   const headers = {
//     Authorization: `Bearer ${bearerToken}`,
//   };
//   useEffect(() => {
//     setIsLoading(true);
//     const fetchData = () => {
//       axios
//         .get(`${baseURL}plan/getPlanList`, {
//           headers: { ...headers },
//         })
//         .then((response) => {
//           const Response = response.data;
//           // console.log(Response)
//           const plansWithRegion = Response.data.map((plan, index) => {
//             let region;
//             switch (index) {
//               case 0:
//                 region = "North America Only";
//                 break;
//               case 1:
//                 region = "North America and EMEA";
//                 break;
//               case 2:
//                 region = "All";
//                 break;
//               case 3:
//                 region = "All";
//                 break;
//               default:
//                 region = "Unknown";
//             }
//             return { ...plan, region: region, planId: index + 1 };
//           });
//           setPlans(plansWithRegion);
//           setIsLoading(false);
//         })
//         .catch((err) => {
//           setIsLoading(false);
//           if (err.response && err.response.status === 401) {
//             return navigate("/");
//           }
//         });
//     };
//     fetchData();
//   }, []);

//   const makePayment = async (_id, amount, planName, planDetails, callback) => {
//     const response = await fetch(`${baseURL}payment/createPayment`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${bearerToken}`,
//       },
//       body: JSON.stringify({
//         planId: _id,
//         amount,
//         currency: "usd",
//       }),
//     });
//     const responseData = await response.json();
//     if (response.ok) {
//       // Check if response status is OK
//       navigate("/account/billing/showresponce", {
//         state: {
//           paymentIntentResponse: responseData.paymentIntentResponse,
//           planName: planName,
//           planDetails: planDetails,
//           amount: amount,
//           currency: "usd",
//           orderId: responseData.orderId,
//           paymentId: responseData.paymentId,
//         },
//       });
//       if (callback) {
//         callback(); // Call the callback function after navigation
//       }
//     } else {
//       // Handle error response
//       console.error("Error:", responseData.error);
//     }
//   };

//   const convertDaysToMonths = (days) => {
//     const months = Math.floor(days / 30); // Divide days by 30 to get months
//     return months >= 1
//       ? `${months} ${months > 1 ? "months" : "month"} validity`
//       : `Contact Sales`;
//   };

//   return (
//     <>
//       {isLoading ? (
//         <div className="d-flex justify-content-center align-items-center vh-100">
//           {/* <Spinner animation="border" variant="info" /> */}
//           <img src={Loading} alt="" width={25} />
//         </div>
//       ) : (
//         <>
//           <Container>
//             <Row>
//               {/* <NewSidebar2 /> */}
//               <Col lg={12} className="px-0">
//                 {/* <SubNav/> */}
//                 <Col className="p-4">
//                   <div className="pb-3">
//                     <Link
//                       to="/account/billing"
//                       className="text-decoration-none text-black"
//                     >
//                       Back to Billing
//                     </Link>
//                   </div>
//                   <h4 className="fw-bold pb-3">Plans</h4>
//                   <div className="d-flex align-items-center">
//                     <Container fluid>
//                       <Row>
//                         {plans.map((plan) => (
//                           <Col lg={3} key={plan._id} className="my-auto">
//                             <Col
//                               lg={12}
//                               className={`border rounded-4  ${
//                                 plan.planName === "Advanced"
//                                   ? "card-hover py-5 px-4"
//                                   : "p-4 "
//                               } `}
//                             >
//                               <h5>
//                                 {plan.planName}
//                                 <span>
//                                   {plan.planName === "Advanced" ? (
//                                     <Badge
//                                       bg="light"
//                                       text="dark"
//                                       className="ms-1"
//                                     >
//                                       Recommended
//                                     </Badge>
//                                   ) : (
//                                     ""
//                                   )}
//                                 </span>
//                               </h5>
//                               <h3 className="fw-bold">
//                                 {plan.planPrice === 0
//                                   ? "Contact Sales"
//                                   : `$ ${plan.planPrice}`}
//                               </h3>

//                               <div>
//                                 {plan.planCredits === 0
//                                   ? "Contact Sales"
//                                   : plan.planCredits}{" "}
//                                 {plan.planCredits === 0 ? "" : "credits"}
//                               </div>
//                               <div>
//                                 {convertDaysToMonths(plan.planDuration)}
//                               </div>
//                               <div className="mb-3"> {plan.region}</div>

//                               <div className="d-grid">
//                                 <Link
//                                   className={`btn-sm btn btn-orange px-4 ${
//                                     plan.planName === "Advanced"
//                                       ? "button-hover"
//                                       : ""
//                                   }`}
//                                   onClick={() =>
//                                     makePayment(
//                                       plan._id,
//                                       plan.planPrice,
//                                       plan.planName,
//                                       plan.planDetails,
//                                       () => window.location.reload()
//                                     )
//                                   }
//                                   type="button"
//                                   disabled={plan.planPrice === 0}
//                                 >
//                                   {plan.planPrice === 0
//                                     ? "Contact Sales"
//                                     : "Buy Now"}
//                                 </Link>
//                               </div>

//                               <div className="mt-2">
//                                 <div>
//                                   <FaCheck /> Voice Validation
//                                 </div>

//                                 <div>
//                                   <FaCheck /> LinkedIn Validation
//                                 </div>

//                                 <div>
//                                   <FaCheck /> Email Validation
//                                 </div>

//                                 <div>
//                                   <FaCheck /> Dupe Check
//                                 </div>

//                                 <div>
//                                   <FaCheck /> Real Time Report Access
//                                 </div>

//                                 <div>
//                                   <FaCheck /> QA Assurance
//                                 </div>
//                               </div>
//                             </Col>
//                           </Col>
//                         ))}
//                       </Row>
//                     </Container>
//                   </div>
//                 </Col>
//               </Col>
//             </Row>
//           </Container>
//         </>
//       )}
//     </>
//   );
// };
// export default Billing;
