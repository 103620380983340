import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import Navbar from "../../components/Navbar.jsx";
import Accordion from "react-bootstrap/Accordion";
import { HiComputerDesktop } from "react-icons/hi2";

function FaqPage() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={9} className="mx-auto">
            <div className="mb-5">
              <Link to="/" className="text-decoration-none text-black">
                Back to Home Page
              </Link>
            </div>
            <h1 className="mb-5 text-center">Frequently Asked Questions</h1>
            <Row>
              <Col>
                <div className="mb-5 text-center">
                  <h4>Need some help?</h4>
                  <h6>Choose category to see related answers</h6>
                </div>
                <Row>
                  <Col lg={4}>
                    <Link to="/faq/general-information" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">General Information</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/getting-started" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Getting Started</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/campaign-management" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Campaign Management</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/account-management" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border rounded-3 d-flex align-items-center mb-3">
                        <p className="mb-0">Account Management</p>
                    </Col>
                  </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/plans-and-billing" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Plans and Billing</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/security-and-privacy" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Security and Privacy</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/troubleshooting" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Troubleshooting</p>
                    </Col>
                    </Link>
                  </Col>
                  <Col lg={4}>
                  <Link to="/faq/help-and-support" className=" text-black text-decoration-none">
                    <Col lg={12} className="p-4 border mb-3 rounded-3 d-flex align-items-center">
                        <p className="mb-0">Help and Support</p>
                    </Col>
                    </Link>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default FaqPage;

{
  /* <Accordion  >
              <Accordion.Item eventKey="0">
                <Accordion.Header><div style={{ fontWeight: 'bold' }}>How does SmartQC enhance data quality?</div></Accordion.Header>
                <Accordion.Body >
                SmartQC improves data quality by leveraging advanced algorithms to detect and correct errors, inconsistencies, 
                and duplicates in your database automatically. It ensures clean, 
                accurate data for more effective decision-making and higher 
                confidence in your business insights.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
              <Accordion.Header>What industries can benefit from using SmartQC?</Accordion.Header>
                <Accordion.Body >
                SmartQC is tailored to meet the needs of various industries such as e-commerce, finance, healthcare, marketing, 
                and more. Its versatile functionality ensures that businesses across different sectors can enhance 
                their data quality and improve decision-making processes.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                 <Accordion.Header >What is SmartQC and what does it do?</Accordion.Header>
                <Accordion.Body >
                SmartQC utilizes cutting-edge algorithms to analyze your data, identify anomalies, and rectify errors. 
                By doing so, it enhances the accuracy,completeness, and reliability of your data, 
                leading to better overall data quality.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                 <Accordion.Header >Is SmartQC compatible with different data formats and systems?</Accordion.Header>
                <Accordion.Body >
                Yes, SmartQC is designed to be compatible with a wide range of data formats and systems, including CSV, Excel, databases, and more.
                It can seamlessly integrate into your existing workflows without any hassle.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                 <Accordion.Header >How easy is it to integrate SmartQC into existing workflows?</Accordion.Header>
                <Accordion.Body >
                Integrating SmartQC into your existing workflows is straightforward and user-friendly. With our intuitive interface and comprehensive documentation,
                you can quickly set up and start using SmartQC with minimal effort.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                 <Accordion.Header >What kind of errors or inconsistencies can SmartQC detect and correct?</Accordion.Header>
                <Accordion.Body >
                SmartQC can detect and correct various types of errors, including missing values, duplicates, 
                inconsistencies in formatting, and outliers. It ensures that your data is clean, standardized, 
                and ready for analysis.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                 <Accordion.Header >Can SmartQC handle large volumes of data?</Accordion.Header>
                <Accordion.Body >
                Yes, SmartQC is capable of processing large volumes of data efficiently and effectively. Whether you have thousands or millions of records, 
                SmartQC can handle it with ease, ensuring that your data quality remains high regardless of scale.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                 <Accordion.Header >What are the key features of SmartQC?</Accordion.Header>
                <Accordion.Body >
                Some key features of SmartQC include automatic error detection and correction, customizable rules and thresholds, scalability, 
                integration with existing systems, and comprehensive reporting and analytics.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                 <Accordion.Header >How does SmartQC ensure data security and privacy?</Accordion.Header>
                <Accordion.Body >
                Security and privacy are top priorities for us. SmartQC employs robust encryption techniques and adheres to industry best practices 
                to ensure that your data remains secure and confidential at all times.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                 <Accordion.Header >What support options are available for users of SmartQC?</Accordion.Header>
                <Accordion.Body >
                We offer various support options, including email support, online documentation, video tutorials, and dedicated customer support representatives.
                Our goal is to ensure that you have all the resources and assistance you need to make the most out of SmartQC.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */
}
