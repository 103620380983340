import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import SubNav from "../../../components/subNav";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { Collapse } from "react-bootstrap";
import NewSidebar2 from "../../../components/NewSidebar2";
import UsageChart from "../../../charts/UsageChart"
import * as Icon from "react-bootstrap-icons";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Profile = () => {
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [user, setUser] = useState({ firstName: "", lastName: "", email: "" });
  const [securityPassword, setsecurityPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [emailNotification, setEmailNotification] = useState(false);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const navigate = useNavigate();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const changeEmailNotification = () => {
    setEmailNotification(!emailNotification);
  };
  // console.log(emailNotification);
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          const { firstName, lastName, email } = response.data.data;
          setUser({
            firstName,
            lastName,
            email,
            status: response.data.data.status,
          });
          // console.log(user);
          // console.log(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            return navigate("/login");
          }
          setIsLoading(false);
          console.error(err?.response?.data?.message);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== password1) {
      toast("Passwords do not match!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    } else {
      // console.log("Passwords matched. Submitting form...");

      const payload = {
        password,
        password1,
      };
      try {
        axios
          .post(`${baseURL}auth/changePassword`, payload, {
            headers,
          })
          .then((res) => {
            //  console.log(res.data.status)
            window.location.reload();
            if (res.data.status === 200) {
              toast("Password Change Successfully!", {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
              // setBearerToken(res.data.newToken);
              // navigate("/");
            } else {
              toast("Failed to generate new token!", {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                // transition: Bounce,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            toast("Password Change Failed!", {
              position: "bottom-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              // transition: Bounce,
            });
          });
      } catch (error) {
        console.error("An error occurred:", error);
        toast("An error occurred. Please try again later.", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      }
    }
  };

  const editUser = async () => {
    try {
      const response = await axios.post(
        `${baseURL}user/editUserByUserId`,
        { firstName, lastName },
        { headers }
      );
      // console.log(response);

      if (response.status === 200) {
        window.location.reload();
        setMessage("User edited successfully.");
        // navigate('/account/profile');
        toast(response.data.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      } else {
        setMessage("Error: " + response.status);
        toast(response.data.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      }
    } catch (error) {
      setMessage("Error: " + error.message);
      toast(error.response.data.message, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    editUser();
  };

  const renderTooltip = (status) => (
    <Tooltip id="tooltip">
      {status === "Active" ? "Verified" : "Not Verified"}
    </Tooltip>
  );

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />
                <Col className="p-4">
                  <div className="row">
                    <h4 className="fw-bold mb-4">Account Settings</h4>

                    <Col lg={2}>
                      <div
                        id="simple-list-example"
                        className="d-flex flex-column gap-2 simple-list-example-scrollspy text-start sticky-top"
                      >
                        <a
                          className="p-1 rounded text-decoration-none text-dark fw-bold"
                          href="#profile"
                        >
                          Profile
                        </a>
                        <a
                          className="p-1 rounded text-decoration-none text-dark fw-bold"
                          href="#security"
                        >
                          Security
                        </a>
                        <a
                          className="p-1 rounded text-decoration-none text-dark fw-bold"
                          href="#notification"
                        >
                          Notifications
                        </a>
                        <a
                          className="p-1 rounded text-decoration-none text-dark fw-bold"
                          href="#usage"
                        >
                          Usage
                        </a>
                      </div>
                    </Col>

                    <Col lg={10}>
                      <div
                        data-bs-spy="scroll"
                        data-bs-target="#simple-list-example"
                        data-bs-offset="0"
                        data-bs-smooth-scroll="true"
                        className="scrollspy-example"
                        tabIndex="0"
                      >
                        <div className="bg-white p-4 rounded-3 mb-3">
                          <h5 className="fw-bold" id="profile">
                            Profile
                          </h5>
                          <br />
                          <Row>
                            <Col lg={6}>
                              <p>CONTACT EMAIL</p>
                            </Col>
                            <Col lg={6}>
                              <p>
                                {user.email}{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip(user.status)}
                                >
                                  {user.status === "Active" ? (
                                    <Icon.PatchCheckFill className="text-success" />
                                  ) : (
                                    <Icon.ExclamationTriangleFill className="text-warning" />
                                  )}
                                </OverlayTrigger>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <p>FULL NAME</p>
                            </Col>
                            <Col lg={6}>
                              <p>
                                {user.firstName} {user.lastName}
                              </p>
                              <div className="d-inline-flex gap-2 mb-2">
                                <a
                                  // variant="primary"
                                  className="btn btn-sm btn-orange"
                                  onClick={() => setOpen1(!open1)}
                                  aria-controls="collapseExample"
                                  aria-expanded={open1}
                                >
                                  Edit
                                </a>
                              </div>
                            </Col>
                            {/* <Col>
                              <p>
                                {`${user.firstName} ${user.lastName}`}{" "}
                                <a
                                  // variant="outline-secondary"
                                  className="ms-2 btn-sm btn btn-orange"
                                >
                                  Edit
                                </a>
                              </p>
                            </Col> */}
                            <Col className="text-end ms-auto" lg={6}>
                              <Collapse in={open1}>
                                {/* <div id="collapseExample" className="card card-body"> */}
                                {/* <Row> */}
                                {/* <Col lg={12} className="text-start"> */}
                                <Col
                                  lg={12}
                                  className="p-4 border rounded-3 bg-white"
                                >
                                  <Form onSubmit={handleSubmit1}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                        className="rounded-3"
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="text"
                                        value={lastName}
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                        placeholder="Last Name"
                                        className="rounded-3"
                                      />
                                    </Form.Group>

                                    <div className="d-grid gap-2">
                                      <Button
                                        variant="btn btn-orange"
                                        type="submit"
                                        className="rounded-3 btn-sm"
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </Form>
                                </Col>
                                {/* </Col> */}
                                {/* </Row> */}
                                {/* </div> */}
                              </Collapse>
                            </Col>
                          </Row>
                        </div>
                        <div className="bg-white p-4 rounded-3 mb-3">
                          <h5 className="fw-bold" id="security">
                            Security
                          </h5>
                          <br />

                          <Row>
                            <Col>
                              <p>Password</p>
                            </Col>
                            <Col className="text-start">
                              <div className="d-inline-flex gap-2 mb-2">
                                <a
                                  // variant="primary"
                                  className="btn btn-sm btn-orange"
                                  onClick={() => setOpen(!open)}
                                  aria-controls="collapseExample"
                                  aria-expanded={open}
                                >
                                  Change Password
                                </a>
                              </div>
                              <Collapse in={open}>
                                {/* <div id="collapseExample" className="card card-body"> */}
                                {/* <Row> */}
                                {/* <Col lg={12} className="text-start"> */}
                                <Col
                                  lg={12}
                                  className="p-4 border rounded-3 bg-white"
                                >
                                  <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="password"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                        className="rounded-3"
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Control
                                        type="password"
                                        value={password1}
                                        onChange={(e) =>
                                          setPassword1(e.target.value)
                                        }
                                        placeholder="Confirm New Password"
                                        className="rounded-3"
                                      />
                                    </Form.Group>

                                    <div className="d-grid gap-2">
                                      <Button
                                        variant="btn btn-orange"
                                        type="submit"
                                        className="rounded-3 btn-sm"
                                      >
                                        Reset Password
                                      </Button>
                                    </div>
                                  </Form>
                                </Col>
                                {/* </Col> */}
                                {/* </Row> */}
                                {/* </div> */}
                              </Collapse>
                            </Col>
                          </Row>
                        </div>
                        <div className="bg-white p-4 rounded-3 mb-3">
                          <h5 className="fw-bold" id="notification">
                            Notifications
                          </h5>
                          <br />
                          <Row>
                            <Col lg={6} className="my-auto">
                              <p>Email</p>
                            </Col>
                            <Col className="text-start">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                onClick={changeEmailNotification}
                                className="form-control-lg"
                                // label="Check this switch"
                              />
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col lg={6} className="my-auto">
                              <p>Push Notifications</p>
                            </Col>
                            <Col className="text-start">
                              <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                className="form-control-lg"
                                // label="Check this switch"
                              />
                            </Col> */}
                          </Row>
                        </div>
                        <div className="bg-white p-4 rounded-3 mb-3">
                          <h5 className="fw-bold" id="usage">
                            Usage
                          </h5>
                          <br />
                          <Row>
                            <Col lg={12}>
                              <UsageChart />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
