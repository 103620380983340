import React from 'react'
import Navbar from "../../../components/Navbar.jsx";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function AppFeat() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">App Features</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How easy is it to integrate your product with our existing systems?
                </Accordion.Header>
                <Accordion.Body>
                  Our product is designed to be easily integrated with existing systems. We provide leads with the future of data quality. Our support team can also assist you with the integration process.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Can I request a demo or trial?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer demos/trials to prospective customers. This allows you to experience our product/service firsthand and see how it fits your needs.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AppFeat