import { useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Loading from "../../../assets/img/dashbord/loading.gif";


const CreateCampaign = () => {
  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const location = useLocation();
  const { campaignName, leadFields } = location.state;

  const fetchData = () => {
    axios
      .post(
        `${baseURL}campaign/createCampaign`,
        {
          leadFields: leadFields,
          campaignName: campaignName,
        },
        { headers: { ...headers } }
      )
      .then((response) => {
        const campaignId = response.data.data._id;
        // console.log(campaignId);
        navigate(`/campaign/${campaignId}`);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
         
        });
        navigate(`/createcampagin/create-type`);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      {/* <Spinner animation="border" variant="info" /> */}
      <img src={Loading} alt="" width={25} />
    </div>
  );
};

export default CreateCampaign;
