import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Pagination,
  Dropdown,
} from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import SubNav from "../../../components/subNav";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Loading from "../../../assets/img/dashbord/loading.gif";
import NewSidebar2 from "../../../components/NewSidebar2";
import * as Icon from "react-bootstrap-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntries, setShowEntries] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [user, setUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // State for managing current page
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`${baseURL}plan/getPlanList`, {
          headers: { ...headers },
        })
        .then((response) => {
          const Response = response.data;
          setPlans(Response);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
        });
    };
    fetchData();
  }, []);

  const makePayment = async (amount, planName, planDetails) => {
    const response = await fetch(`${baseURL}payment/createPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({ amount }),
    });
    const responseData = await response.json();
    if (response.ok) {
      navigate("/showresponce", {
        state: {
          paymentIntentResponse: responseData.paymentIntentResponse,
          planName: planName,
          planDetails: planDetails,
        },
      });
    } else {
      console.error("Error:", responseData.error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          page: currentPage.toString(), // Include current page in the request
          start: ((currentPage - 1) * entriesPerPage).toString(), // Calculate start index based on current page and entries per page
          length: entriesPerPage.toString(),
          columns: [
            {
              data: "paymentId",
              name: "",
              searchable: true,
              orderable: true,
              search: {
                value: "",
                regex: false,
              },
            },
            {
              data: "status",
              name: "",
              searchable: true,
              orderable: true,
              search: {
                value: "",
                regex: false,
              },
            },
            {
              data: "currency",
              name: "",
              searchable: true,
              orderable: true,
              search: {
                value: "",
                regex: false,
              },
            },
          ],
          order: [
            {
              column: 0,
              dir: "desc",
            },
          ],
          search: {
            value: searchTerm,
            regex: false,
          },
        };
        const response = await axios.post(
          `${baseURL}payment/getDataTableForPaymentByUserId`,
          payload,
          { headers }
        );
        console.log(response);
        setPayments(response.data.data);
        setShowEntries(response.data.recordsRange);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, [searchTerm, entriesPerPage, currentPage]);

  function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${date.getDate()} ${month}, ${year}`;
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEntriesChange = (e) => {
    setEntriesPerPage(e.target.value);
    setCurrentPage(1); // Reset current page to 1 when changing entries per page
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  // const handleDownload = async () => {
  //   try {
  //     const payload = {
  //       page: currentPage.toString(),
  //       start: ((currentPage - 1) * entriesPerPage).toString(),
  //       length: entriesPerPage.toString(),
  //       columns: [
  //         {
  //           data: "paymentId",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //         {
  //           data: "status",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //         {
  //           data: "currency",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //       ],
  //       order: [
  //         {
  //           column: 0,
  //           dir: "desc",
  //         },
  //       ],
  //       search: {
  //         value: searchTerm,
  //         regex: false,
  //       },
  //     };

  //     const response = await axios.post(
  //       `${baseURL}payment/getDataTableForPaymentByUserId`,
  //       payload,
  //       { headers }
  //     );

  //     const jsonString = JSON.stringify(response.data, null, 2); // Convert JSON to string with formatting

  //     const doc = new jsPDF();
  //     doc.text(jsonString, 10, 10); // Add the JSON string to the PDF
  //     doc.save('invoice.pdf');
  //   } catch (error) {
  //     console.error('Error downloading the PDF', error);
  //   }
  // };

  // const handleDownload = async (selectedPaymentId) => {
  //   try {
  //     const payload = {
  //       page: currentPage.toString(),
  //       start: ((currentPage - 1) * entriesPerPage).toString(),
  //       length: entriesPerPage.toString(),
  //       columns: [
  //         {
  //           data: "paymentId",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //         {
  //           data: "status",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //         {
  //           data: "currency",
  //           name: "",
  //           searchable: true,
  //           orderable: true,
  //           search: {
  //             value: "",
  //             regex: false,
  //           },
  //         },
  //       ],
  //       order: [
  //         {
  //           column: 0,
  //           dir: "desc",
  //         },
  //       ],
  //       search: {
  //         value: searchTerm,
  //         regex: false,
  //       },
  //     };
  
  //     const response = await axios.post(
  //       `${baseURL}payment/getDataTableForPaymentByUserId`,
  //       payload,
  //       { headers }
  //     );
  
  //     let filteredData = response.data.data;
  
  //     if (selectedPaymentId) {
  //       filteredData = filteredData.filter(item => item.paymentId === selectedPaymentId);
  //     }
  
  //     if (filteredData.length === 1) {
  //       // If there is exactly one item, format it as a single object
  //       const selectedData = filteredData[0];
  
  //       const formattedData = {
  //         _id: selectedData._id,
  //         paymentId: selectedData.paymentId,
  //         userId: {
  //           _id: selectedData.userId._id,
  //           firstName: selectedData.userId.firstName,
  //           lastName: selectedData.userId.lastName,
  //           email: selectedData.userId.email,
  //         },
  //         planId: {
  //           _id: selectedData.planId._id,
  //           planName: selectedData.planId.planName,
  //         },
  //         amount: selectedData.amount,
  //         currency: selectedData.currency,
  //         status: selectedData.status,
  //         createdAt: selectedData.createdAt,
  //         updatedAt: selectedData.updatedAt,
  //         __v: selectedData.__v,
  //       };
  
  //       // Convert the object to a JSON string with formatting
  //       const jsonString = JSON.stringify(formattedData, null, 2);
  
  //       // Create a new jsPDF instance and add the formatted JSON string to the PDF
  //       const doc = new jsPDF();
  //       doc.text(jsonString, 10, 10);
  //       doc.save('invoice.pdf');
  //     } else {
  //       console.error('No data found or multiple entries returned for the given paymentId');
  //     }
  //   } catch (error) {
  //     console.error('Error downloading the PDF', error);
  //   }
  // };

  const noDataMessage = () => {
    if (user.planName === "Free") {
      return "No invoice history found";
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        });
        const { credits, firstName, lastName, planName, planExpiresOn, _id } = response.data.data;
        setUser({ credits, firstName, lastName, planName, planExpiresOn, _id });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        if (err.response && err.response.status === 401) {
          return navigate("/");
        }
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />
                <Col className="p-4">
                  <Link to="/account/billing" className="mb-3 text-decoration-none text-dark">
                    Back to Billing
                  </Link>
                  <h4 className="fw-bold py-3">Invoice History</h4>
                  <Row className="mb-3">
                    <Col lg={12}>
                      <div className="d-flex align-items-center mb-2">
                        <Col lg={8}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                        </Col>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {payments.length === 0 ? (
                      <Col lg={12} className="text-center">
                        {noDataMessage()}
                      </Col>
                    ) : (
                      payments.map((payment, index) => (
                        <Col lg={12} key={index}>
                          <Col lg={12} className="p-3 mb-3 bg-white rounded-3">
                            <Row>
                              <Col>
                                <h5>{payment.paymentId}</h5>
                              </Col>
                              <Col>
                                <h5>{payment.planId.planName} Plan</h5>
                              </Col>
                              <Col>
                                <h5>{getFormattedDate(payment.createdAt)}</h5>
                              </Col>
                              <Col>
                                <h5>{payment.amount} {payment.currency.toUpperCase()}</h5>
                              </Col>
                              <Col>
                                <h5>{payment.status === "CREATED" ? "Pending" : "Completed"}</h5>
                              </Col>
                              <Col className="text-end">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    size="sm"
                                    id="dropdown-basic"
                                    className="dropdown-toggle-no-arrow"
                                  >
                                    <Icon.ThreeDotsVertical />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => navigate(`/downloadPage/${payment._id}`)}>
                                      Download
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      ))
                    )}
                  </Row>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Billing;
