import { Button, Container, Row, Col } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import SubNav from "../../../components/subNav";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import NewSidebar2 from "../../../components/NewSidebar2";
import Loading from "../../../assets/img/dashbord/loading.gif";
import Coin from "../../../assets/img/home/coin.png";

const Billing = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({ credits: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}user/getUserDetailsByUserId`,
          {
            headers: { ...headers },
          }
        );
        const {
          credits,
          status,
          firstName,
          lastName,
          planName,
          planExpiresOn,
          _id,
        } = response.data.data;
        setUser({
          credits,
          firstName,
          lastName,
          planName,
          planExpiresOn,
          _id,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.status === 401) {
          return navigate("/");
        }
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${date.getDate()} ${month}, ${year}`;
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <NewSidebar2 />
            <Col lg={10} className="px-0">
              <SubNav />
              <Col className="p-4">
                <Row>
                  <h4 className="fw-bold mb-4">Billing Information</h4>

                  <Col lg={2}>
                    <div
                      id="simple-list-example"
                      className="d-flex flex-column gap-2 simple-list-example-scrollspy text-start"
                    >
                      <a
                        className="p-1 rounded text-decoration-none text-dark fw-bold"
                        href="#plan"
                      >
                        {" "}
                        Plan
                      </a>
                      <a
                        className="p-1 rounded text-decoration-none text-dark fw-bold"
                        href="#creaditbalance"
                      >
                        {" "}
                        Credit Balance
                      </a>
                      <a
                        className="p-1 rounded text-decoration-none text-dark fw-bold"
                        href="#invoicehistory"
                      >
                        {" "}
                        Invoice History
                      </a>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <div
                      data-bs-spy="scroll"
                      data-bs-target="#simple-list-example"
                      data-bs-offset="0"
                      data-bs-smooth-scroll="true"
                      className="scrollspy-example"
                      tabIndex="0"
                    >
                      <div className="bg-white p-4 rounded-3 mb-3">
                        <h5 className="border-bottom fw-bold pb-3" id="plan">
                          Plan
                        </h5>
                        <br />
                        <Row>
                          <Col>
                            <p>{user.planName}</p>
                            <p>
                              Expires on {getFormattedDate(user.planExpiresOn)}
                            </p>
                          </Col>
                          <Col className="text-end">
                            <a
                              // variant="outline-secondary"
                              className="ms-2 btn btn-sm btn-orange"
                              onClick={() => navigate("/account/billing/plans")}
                            >
                              Change Plan
                            </a>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-white p-4 rounded-3 mb-3">
                        <h5
                          className="border-bottom fw-bold pb-3"
                          id="creaditbalance"
                        >
                          Credit Balance
                        </h5>
                        <br />
                        <Row>
                          <Col>
                            <p>Total balance</p>
                            <h5 >
                              {user.credits}
                              <img
                                src={Coin}
                                alt="Flipping Coin"
                                width={25}
                                className="flip-coin me-5"
                              />
                            </h5>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-white p-4 rounded-3 mb-3">
                        <h5 className="fw-bold" id="invoicehistory">
                          Invoice History
                        </h5>
                        <p className="border-bottom pb-3">
                          View or download your past invoices.
                        </p>
                        <Row>
                          <Col className="text-start">
                            <a
                              className="btn btn-sm btn-orange"
                              onClick={() => {
                                if (user._id) {
                                  navigate(
                                    `/account/billing/invoices/${user._id}`
                                  );
                                } else {
                                  console.error("User ID is undefined");
                                }
                              }}
                            >
                              View All
                            </a>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Billing;
