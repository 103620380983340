import React from 'react'
import Navbar from "../../../components/Navbar.jsx";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "../../../components/Footer.jsx";

function GettingStart() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="mb-5">
              <Link to="/faq" className="text-decoration-none text-black">
                Back to FAQ
              </Link>
            </div>
            <h1 className="mb-5 text-start">Getting Started</h1>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How do I get started with SmartQC?
                </Accordion.Header>
                <Accordion.Body>
                  Getting started with SmartQC is easy! Simply <Link to="/register" className='text-decoration-none'>sign up</Link> for an account on our website, and you'll receive instructions on how to set up your account and start using our platform.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Can I request a demo or trial?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer demos/trials to prospective customers. This allows you to experience our product/service firsthand and see how it fits your needs.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )
}

export default GettingStart