import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import FirstStep from "../../../assets/img/create-type/first step.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import SubNav from "../../../components/subNav";
import { Button, Container } from "react-bootstrap";
import { Row, Col, Spinner } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NewSidebar2 from "../../../components/NewSidebar2";
import Loading from "../../../assets/img/dashbord/loading.gif";

const CampaginTyle = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [disbleButton, setDisableButton] = useState(false);
  const [step, setStep] = useState(1);
  const [leadsData, setLeadsData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [disbleButton, setDisableButton] = useState(false);
  const [leadFields, setLeadFields] = useState([
    { name: "first_name", type: "String", label: "First Name" },
    { name: "last_name", type: "String", label: "Last Name" },
    { name: "company_name", type: "String", label: "Company Name" },
    // { name: "job_title", type: "String", label: "Job Title" },
    // { name: "phone_number", type: "String", label: "Phone Number" },
    { name: "email", type: "String", label: "Email Address" },
    // { name: "industry", type: "String", label: "Industry" },
    // { name: "status", type: "String", label: "Status" },
  ]);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const fetchData = () => {
    setLoading(true); // Start loading
    axios
      .get(`${baseURL}leadField/getLeadFields`, {
        headers: { ...headers },
      })
      .then((response) => {
        const responseData = response.data;
        // console.log(responseData);
        const leadsArray = Array.isArray(responseData)
          ? responseData
          : responseData.data;
        setLeadsData(leadsArray);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
         
        });
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleLeadFieldClick = (fieldName) => {
    const isDisabledField = [
      "first_name",
      "last_name",
      "company_name",
      "email",
      // "industry",
    ].includes(fieldName);

    if (isDisabledField) {
      return;
    }

    const isSelected = leadFields.find((field) => field.name === fieldName);

    if (isSelected) {
      const updatedLeadFields = leadFields.filter(
        (field) => field.name !== fieldName
      );
      setDisableButton(true);
      setLeadFields(updatedLeadFields);
      setLeadsData((prevLeadsData) => [...prevLeadsData, isSelected]);
    } else {
      const leadField = leadsData.find((field) => field.name === fieldName);
      if (leadField) {
        setLeadFields((prevSelected) => [...prevSelected, leadField]);
      }
      const updatedLeadsData = leadsData.filter(
        (field) => field.name !== fieldName
      );
      setLeadsData(updatedLeadsData);
    }

    // Disable the clicked button
    const updatedLeadsData = leadsData.map((field) =>
      field.name === fieldName ? { ...field, disabled: true } : field
    );
    setLeadsData(updatedLeadsData);
  };

  const handleDone = () => {
    navigate("/createcampaign", {
      state: {
        leadFields,
        campaignName,
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {/* <Spinner animation="border" variant="info" /> */}
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <NewSidebar2 />
              <Col lg={10} className="px-0">
                <SubNav />
                <Col lg={12} className="mx-auto p-4">
                  <h4 className="mb-4 fw-bold">New Campaign</h4>
                  <Form.Control
                    placeholder="Name"
                    className="mb-4 rounded-3"
                    required
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                  />

                  <Row>
                    <Col className="mb-4">
                      <p>Select the fields for your campaign</p>
                      {leadsData.map((leadField, index) => {
                        const isSelected = leadFields.find(
                          (selected) => selected.name === leadField.name
                        );

                        return (
                          <React.Fragment key={index}>
                            {isSelected ? (
                              <button
                                className="btn btn-success text-white border-0 me-2 mb-2"
                                disabled={false}
                                onClick={() =>
                                  handleLeadFieldClick(leadField.name)
                                }
                              >
                                {leadField.label}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-secondary me-2 mb-2"
                                onClick={() =>
                                  handleLeadFieldClick(leadField.name)
                                }
                              >
                                {leadField.label}
                              </button>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                  </Row>
                  <Button variant="btn btn-orange" onClick={handleDone}>
                    Continue
                  </Button>
                </Col>
                {/* </Row> */}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CampaginTyle;
