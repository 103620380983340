// import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
// import React from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { useLocation, useNavigate } from "react-router-dom";
// import { ClientIdPaypal } from "../../../utils/constant";

// function PaymentResponce() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { planName, planDetails, amount, currency } = location.state;

//   return (
//     <>
//       <Container>
//         <Row>
//           <Col className="d-flex justify-content-center align-items-center vh-100">
//             <Col lg={6} md={10} className="border p-4 rounded-4 shadow">
//               <PayPalScriptProvider
//                 options={{
//                   "client-id": `${ClientIdPaypal}`,
//                   components: "buttons,funding-eligibility",
//                 }}
//               >
//                 <div className="text-center pb-3 border-bottom fw-bold">
//                   <h3 className="">Order Summary</h3>
//                 </div>
//                 <p className="mt-3">
//                   Total: {amount} {currency}
//                 </p>
//                 {/* <p>Plan Name: {planName}</p> */}
//                 {/* <p>Plan Details: {planDetails}</p> */}

//                 <PayPalButtons
//                   style={{ layout: "vertical" }}
//                   fundingSource="paypal"
//                   createOrder={(data, actions) => {
//                     return actions.order.create({
//                       purchase_units: [
//                         {
//                           amount: {
//                             value: amount,
//                           },
//                         },
//                       ],
//                     });
//                   }}
//                   onApprove={(data, actions) => {
//                     return actions.order.capture().then(function (details) {
//                       if (details.status === "COMPLETED") {
//                         navigate("/success");
//                         setTimeout(() => {
//                           navigate("/account/billing");
//                         }, 4000);
//                       } else {
//                         navigate("/error");
//                       }
//                     }
//                   );
//                   }}
//                   amount={amount}
//                 />
//               </PayPalScriptProvider>
//             </Col>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// }

// export default PaymentResponce;
// import React, { useState } from "react";
// import {
//   PayPalButtons,
//   PayPalScriptProvider,
//   usePayPalScriptReducer,
// } from "@paypal/react-paypal-js";
// import { ClientIdPaypal } from "../../../utils/constant";
// import { Col, Container, Row } from "react-bootstrap";

// const Checkout = () => {
//   const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
//   const [orderId, setOrderId] = useState(null);

//   const onCreateOrder = (data, actions) => {
//     return actions.order
//       .create({
//         purchase_units: [
//           {
//             amount: {
//               value: "8.99",
//               currency_code: "USD", // Make sure to add currency_code
//             },
//           },
//         ],
//       })
//       .then((orderID) => {
//         console.log("Order created:", orderID);
//         setOrderId(orderID);
//         return orderID;
//       })
//       .catch((error) => {
//         console.error("Error creating order:", error);
//         throw new Error("Failed to create order");
//       });
//   };

//   const onApproveOrder = (data, actions) => {
//     return actions.order
//       .capture()
//       .then((details) => {
//         console.log("Order captured:", details);
//         const name = details.payer.name.given_name;
//         // alert(`Transaction completed by ${name}`);
//       })
//       .catch((error) => {
//         console.error("Error capturing order:", error);
//         throw new Error("Failed to capture order");
//       });
//   };

//   return (
//     <div className="checkout">
//       {isPending ? (
//         <p>LOADING...</p>
//       ) : (
//         <>
//           <PayPalButtons
//             style={{ layout: "vertical" }}
//             createOrder={(data, actions) => onCreateOrder(data, actions)}
//             onApprove={(data, actions) => onApproveOrder(data, actions)}
//           />
//         </>
//       )}
//     </div>
//   );
// };
import React, { useState } from "react";
import { ClientIdPaypal } from "../../../utils/constant";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Col, Container, Row } from "react-bootstrap";

function PaypalIntegration() {
  const [amount, setAmount] = useState(1000); // Set a default amount for testing

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div>
      <input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter amount"
      />
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                  currency_code: "USD",
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            alert("Transaction completed by " + details.payer.name.given_name);
            console.log("Capture details:", details);
            console.log("Order data:", data);
            // Handle the captured order details here
          });
        }}
        onError={(error) => {
          console.error("Error:", error);
        }}
        onCancel={(data) => {
          console.log("Payment cancelled", data);
        }}
      />
    </div>
  );
}

const PayPalWrapper = () => {
  return (
    <PayPalScriptProvider options={{ "client-id": ClientIdPaypal }}>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center align-items-center vh-100">
            <Col lg={7}>
              <PaypalIntegration />
            </Col>
          </Col>
        </Row>
      </Container>
    </PayPalScriptProvider>
  );
};

export default PayPalWrapper;
